import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI, getFilenameFromResponse } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, share } from "rxjs/operators";

@Injectable()
export class ExportHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public listVerfahrensleiter(customHeaders?: HttpHeaders) {
    return this.httpService.post<Blob>(URI`/api/Export/ListVerfahrensleiter`, 'blob', null, customHeaders).pipe(
      map((response: HttpResponse<Blob>) => ({ filename: getFilenameFromResponse(response), blob: response.body })),
      share()
    );
  }
}