import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h4-output',
  templateUrl: './h4-output.component.html'
})
export class H4OutputComponent {
  @Input() text: string;

  constructor() { }
}
