import { Component, Input } from '@angular/core';
import { DownloadService } from '../services/download.service';


@Component({
  selector: 'app-download',
  templateUrl: './download.component.html'
})
export class DownloadComponent {

  @Input() blobId: number;
  @Input() fileName: string = '';
  @Input() label: string = '';
  @Input() iconOnly: boolean;
  @Input() isDeleted: boolean;
  @Input() alternateText: string;

  constructor(private downloadService: DownloadService) { }

  download() {
    this.downloadService.downloadFile(this.blobId, this.fileName);
    return false;
  }
}