import { Pipe, PipeTransform } from "@angular/core";
import { SupportStatus } from "../../generated";

@Pipe({
 name: 'status'
})
export class StatusPipe implements PipeTransform {
 transform(e: SupportStatus): any {
  switch (e) {
   case SupportStatus.Offen:
    return "Offen";
   case SupportStatus.InArbeit:
    return "In Arbeit";
   case SupportStatus.Erledigt:
    return "Erledigt";
   case SupportStatus.OnHold:
    return "On hold";
   case SupportStatus.NichtErmittelbar:
    return 'Nicht Ermittelbar';
   case SupportStatus.FehlerTicketSystem:
    return '(Fehlerhafter Status)'
  }
 }
}
