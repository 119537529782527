import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { KundeTM, KundeDropDownItemTM, KundeListRequestTM, PagedResult, KundeListItemTM } from "./models";
import { map, share } from "rxjs/operators";

@Injectable()
export class KundeHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public get(id: number, customHeaders?: HttpHeaders) {
    return this.httpService.get<KundeTM>(URI`/api/Kunde/Get`, 'json', { id }, customHeaders).pipe(
      map((response: HttpResponse <KundeTM>) => response.body),
      share()
    );
  }
  
  public dropDownList(customHeaders?: HttpHeaders) {
    return this.httpService.post<KundeDropDownItemTM[]>(URI`/api/Kunde/DropDownList`, 'json', null, customHeaders).pipe(
      map((response: HttpResponse <KundeDropDownItemTM[]>) => response.body),
      share()
    );
  }
  
  public list(request: KundeListRequestTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<PagedResult<KundeListItemTM>>(URI`/api/Kunde/List`, 'json', request, customHeaders).pipe(
      map((response: HttpResponse <PagedResult<KundeListItemTM>>) => response.body),
      share()
    );
  }
}