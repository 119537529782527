import { Component, Input, ViewChild, ElementRef, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Base64File } from '../../generated/models';

@Component({
  selector: 'app-base64-multiple-file',
  templateUrl: './base64-multiple-file-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => Base64MultipleFileComponent),
    multi: true
  }]
})
export class Base64MultipleFileComponent implements ControlValueAccessor {

  private onChange = (files: Base64File[] | null) => { };
  private onTouched = () => { };

  files: Base64File[] | null = null;
  disabled: boolean;

  @Input() id: string;
  @Input() allowedFileTypes = "";
  @Input() required: boolean;
  @Input() placeholder = "";

  @Output() onFileDrop = new EventEmitter<Base64File[]>();

  @ViewChild('input', { static: false }) input: ElementRef;

  writeValue(files: Base64File[] | null): void {
    this.files = files;
  }

  registerOnChange(fn: (files: Base64File[] | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async change(event: Event) {
    const input = event.target as HTMLInputElement;
    this.files = await this.getBase64(input.files);
    this.onChange(this.files);
    this.onTouched();
    this.onFileDrop.next(this.files);
  }

  blur() {
    this.onTouched();
  }

  clear() {
    this.files = null;
    this.onChange(this.files);
    this.onTouched();
    const element = this.input.nativeElement as HTMLInputElement;
    try {
      element.type = 'text';
      element.type = 'file';
    } catch (error) { }

  }

  private async getBase64(files: FileList): Promise<Base64File[]> {

    if (!files.length) {
      return Promise.resolve(undefined);
    }

    let reader = new FileReader();
    let ret: Base64File[] = [];

    for (let i = 0; i < files.length; i++) {
      reader.readAsDataURL(files[i]);

      ret.push(await new Promise<Base64File>((resolve, reject) => {
        reader.onload = () => resolve({
          data: (reader.result as string).split(',')[1],
          filename: files[i].name,
          contentType: null
        });
        reader.onerror = reject;
      }));
    }

    return ret;
  }
}