import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-textbox-input',
  templateUrl: './textbox-input.component.html'
})
export class TextBoxInputComponent {

  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() isPassword: boolean;
  @Input() unit: string = '';
  @Input() autocomplete: string = null;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() infoText: string = "";

}
