import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h2-output',
  templateUrl: './h2-output.component.html'
})
export class H2OutputComponent {
  @Input() text: string;

  constructor() { }
}
