import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, share } from "rxjs/operators";

@Injectable()
export class DateiKategorieHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public getByName(name: string, customHeaders?: HttpHeaders) {
    return this.httpService.get<number>(URI`/api/DateiKategorie/GetByName`, 'json', { name }, customHeaders).pipe(
      map((response: HttpResponse <number>) => response.body),
      share()
    );
  }
  
  public setDateiKategorie(did: number, kid: number, customHeaders?: HttpHeaders) {
    return this.httpService.post<number>(URI`/api/DateiKategorie/SetDateiKategorie`, 'json', null, { did, kid }, customHeaders).pipe(
      map((response: HttpResponse <number>) => response.body),
      share()
    );
  }
}