import { Dialog, DialogService } from '@oevermann/angular';
import { CrudService } from './crud.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ResourceProvider } from './resource-provider';

const discardSaveDialog: (rp: ResourceProvider) => Dialog = (rp) => ({
  config: {
    header: rp.getResource('dialog.discard.header'),
  },
  translate: false,  
  content: rp.getResource('dialog.discard.contentCanSave'),
  buttons: [
    {
      label: rp.getResource('labels.save'),
      id: 'save',
      class: 'p-button-primary'
    },
    {
      label: rp.getResource('labels.discardChanges'),
      id: 'discard',
      class: 'p-button-secondary'
    },
    {
      label: rp.getResource('labels.cancel'),
      id: 'cancel',
      class: 'p-button-secondary'
    }
  ]
});

const discardDialog: (rp: ResourceProvider) => Dialog = (rp) => ({
  config: {
    header: rp.getResource('dialog.discard.header'),
  },
  translate: false,
  content: rp.getResource('dialog.discard.contentCanSave'),
  buttons: [
    {
      label: rp.getResource('labels.discardChanges'),
      id: 'discard',
      class: 'p-button-secondary'
    },
    {
      label: rp.getResource('labels.cancel'),
      id: 'cancel',
      class: 'p-button-secondary'
    }
  ]
} as Dialog);

export function crudToDialog(dialogService: DialogService, resourceProvider: ResourceProvider, crudService: CrudService<any, any, any>) {
  if (dialogService) {
    crudService.discardPromptChanges.pipe(filter(p => p != null), switchMap(prompt => {
      const obs$ = crudService.form.valid
        ? dialogService.open(discardSaveDialog(resourceProvider))
        : dialogService.open(discardDialog(resourceProvider));

      return obs$.pipe(tap(id => {
        if (id === 'save')
          prompt.save();
        else if (id === 'discard') {
          prompt.discard();
        } else if (id === 'cancel') {
          prompt.cancel();
        }
      }));
    })).subscribe();

    crudService.deletePromptChanges.pipe(filter(p => p != null), switchMap(prompt => {
      return dialogService.open({
        config: {
          header: resourceProvider.getResource('dialog.delete.header'),
        },
        translate: false,
        content: resourceProvider.getResource('dialog.delete.content'),
        buttons: [
          {
            label: resourceProvider.getResource('labels.yes'),
            id: 'yes',
            class: 'p-button-danger'
          },
          {
            label: resourceProvider.getResource('labels.no'),
            id: 'no',
            class: 'p-button-secondary'
          }
        ]
      }).pipe(tap(id => {
        if (id === 'yes')
          prompt.delete();
        else if (id === 'no')
          prompt.cancel();
      }));
    })).subscribe((p) => {

    });
  }
}
