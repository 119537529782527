import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { LizenzvertragTM, LizenzvertragListRequestTM, PagedResult, LizenzvertragListTM } from "./models";
import { map, share } from "rxjs/operators";

@Injectable()
export class LizenzHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public get(kundeId: number, id: number, customHeaders?: HttpHeaders) {
    return this.httpService.get<LizenzvertragTM>(URI`/api/Lizenz/Get`, 'json', { kundeId, id }, customHeaders).pipe(
      map((response: HttpResponse <LizenzvertragTM>) => response.body),
      share()
    );
  }
  
  public list(request: LizenzvertragListRequestTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<PagedResult<LizenzvertragListTM>>(URI`/api/Lizenz/List`, 'json', request, customHeaders).pipe(
      map((response: HttpResponse <PagedResult<LizenzvertragListTM>>) => response.body),
      share()
    );
  }
  
  public save(kundeId: number, model: LizenzvertragTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<LizenzvertragTM>(URI`/api/Lizenz/Save`, 'json', model, { kundeId }, customHeaders).pipe(
      map((response: HttpResponse <LizenzvertragTM>) => response.body),
      share()
    );
  }
}