import { Injectable } from "@angular/core";
import { ListService } from "./list.service";
import { PagedResult, LizenzvertragListRequestTM, LizenzvertragListTM } from "./models";
import { Observable } from "rxjs";
import { LizenzHttpService } from "./lizenz-http.service";

const defaults = { ...LizenzvertragListRequestTM.empty(), page: 1, pageSize: 100, sort: null, sortOrder: 1 };

@Injectable()
export class LizenzListService extends ListService<LizenzvertragListRequestTM, LizenzvertragListRequestTM.FormGroup, LizenzvertragListTM> {

  resultChanges: Observable<PagedResult<LizenzvertragListTM>>;
  resultFullPageChanges: Observable<PagedResult<LizenzvertragListTM>>;
  form: LizenzvertragListRequestTM.FormGroup;

  get result(): PagedResult<LizenzvertragListTM> {
    return super.result as PagedResult<LizenzvertragListTM>;
  }

  constructor(private modelHttpService: LizenzHttpService) {
    super(defaults);
  }

  protected getDebounceTime() {
    return 300;
  }

  protected createForm(request: LizenzvertragListRequestTM) {
    return new LizenzvertragListRequestTM.FormGroup(request);
  }

  protected load(request: LizenzvertragListRequestTM) {
    return this.modelHttpService.list(request);
  }

  public reset() {
    this.form.reset(defaults);
  }
}