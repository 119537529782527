import { Pipe, PipeTransform } from "@angular/core";
import { NewsKategorie} from "../../generated/enums";

@Pipe({
 name: 'newsKategorie'
})
export class NewsKategoriePipe implements PipeTransform {
 transform(e: NewsKategorie): any {
  switch (e) {
   case NewsKategorie.NeuerBenutzer:
    return "Neuer Benutzer";
   case NewsKategorie.Feature:
    return "Featue";
   case NewsKategorie.Aktuelles:
    return "Aktuelles";
   case NewsKategorie.Tipps:
    return "Tipps";
  }
 }
}