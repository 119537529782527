import { Injectable } from "@angular/core";
import { ListService } from "./list.service";
import { PagedResult, VerfahrenRequestTM, VerfahrenListItemTM } from "./models";
import { Observable } from "rxjs";
import { VerfahrenHttpService } from "./verfahren-http.service";

const defaults = { ...VerfahrenRequestTM.empty(), page: 1, pageSize: 100, sort: null, sortOrder: 0 };

@Injectable()
export class VerfahrenListService extends ListService<VerfahrenRequestTM, VerfahrenRequestTM.FormGroup, VerfahrenListItemTM> {

  resultChanges: Observable<PagedResult<VerfahrenListItemTM>>;
  resultFullPageChanges: Observable<PagedResult<VerfahrenListItemTM>>;
  form: VerfahrenRequestTM.FormGroup;

  get result(): PagedResult<VerfahrenListItemTM> {
    return super.result as PagedResult<VerfahrenListItemTM>;
  }

  constructor(private modelHttpService: VerfahrenHttpService) {
    super(defaults);
  }

  protected getDebounceTime() {
    return 300;
  }

  protected createForm(request: VerfahrenRequestTM) {
    return new VerfahrenRequestTM.FormGroup(request);
  }

  protected load(request: VerfahrenRequestTM) {
    return this.modelHttpService.list(request);
  }

  public reset() {
    this.form.reset(defaults);
  }
}