import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KundeHttpService } from '../../generated';
import { BaseComponent } from '../base.component';
import { IDDItem } from '../IDDItem';


@Component({
  selector: 'app-kunde-multiselect-input',
  templateUrl: './kunde-multiselect-input.component.html'
})
export class KundeMultiSelectInputComponent extends BaseComponent {

  selectedItemsLabel: string = "{0} Kunden ausgewählt";

  @Input() id: string;
  @Input() label: string;
  @Input() daten: IDDItem[];
  @Input() control: FormControl;
  @Input() readonly: boolean;

  constructor(private kundeHttpService: KundeHttpService) {
    super();

    this.subscription.add(this.kundeHttpService.dropDownList().subscribe(items => {
      this.daten = [...items.map(item => {
        return {
          label: item.bezeichnung.value,
          value: item.id
        };
      })];

      window.setTimeout(() => { this.control.setValue(this.control.value) }, 250); // müssen hier kurz auf die verarbeiteten this.daten warten ...
    }));

  }

}
