import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { EMailDataContainerTM, EMailUpdateTM, EMailCreateTM } from "./models";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, share } from "rxjs/operators";

@Injectable()
export class EMailHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public getDefault(emailContainer: EMailDataContainerTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<EMailUpdateTM>(URI`/api/EMail/GetDefault`, 'json', emailContainer, customHeaders).pipe(
      map((response: HttpResponse <EMailUpdateTM>) => response.body),
      share()
    );
  }
  
  public save(model: EMailCreateTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<EMailCreateTM>(URI`/api/EMail/Save`, 'json', model, customHeaders).pipe(
      map((response: HttpResponse <EMailCreateTM>) => response.body),
      share()
    );
  }
}