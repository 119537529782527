import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-submenu',
	templateUrl: './submenu.component.html'
})
export class SubmenuComponent {

	public id: number;

	constructor(private route: ActivatedRoute) {
		this.id = this.route.snapshot.params.kundeId;
	}

}
