import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { Option, SupportListRequestTM, PagedResult, TicketTm } from "./models";
import { map, share } from "rxjs/operators";

@Injectable()
export class SupportHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public supportStatusOptionsForListRequest(customHeaders?: HttpHeaders) {
    return this.httpService.get<Option[]>(URI`/api/Support/SupportStatusOptionsForListRequest`, 'json', customHeaders).pipe(
      map((response: HttpResponse <Option[]>) => response.body),
      share()
    );
  }
  
  public list(request: SupportListRequestTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<PagedResult<TicketTm>>(URI`/api/Support/List`, 'json', request, customHeaders).pipe(
      map((response: HttpResponse <PagedResult<TicketTm>>) => response.body),
      share()
    );
  }
}