import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox-output',
  templateUrl: './checkbox-output.component.html'
})
export class CheckboxOutputComponent {

  @Input() id: string;
  @Input() name: string;
  @Input() label: string = '';
  @Input() labelAfterBox: boolean = true;
  @Input() title: string;
  @Input() value: boolean;

  constructor() { }

}
