import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-textarea-output',
	templateUrl: './textarea-output.component.html'
})
export class TextareaOutputComponent {

	@Input() label: string;
	@Input() placeholder: string;
	@Input() value: string;

	constructor() { }

}
