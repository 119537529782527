/*
 * Erfolg-String
 */
export const ERFOLG = "Erfolg";

/*
 * Fehler-String
 */
export const FEHLER = "Fehler";

/*
 * Hinweis-String
 */
export const HINWEIS = "Hinweis";



export const UNTERNEHMEN = "Unternehmen";


export const EINKAUF = "Einkauf";

export const FINANZBUCHHALTUNG = "Finanzbuchhaltung";