import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, share } from "rxjs/operators";

@Injectable()
export class DownloadHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public get(id: number, customHeaders?: HttpHeaders) {
    return this.httpService.get<any>(URI`/api/Download/Get`, 'json', { id }, customHeaders).pipe(
      map((response: HttpResponse <any>) => response.body),
      share()
    );
  }
  
  public getBase64Data(id: number, customHeaders?: HttpHeaders) {
    return this.httpService.get<string>(URI`/api/Download/GetBase64Data`, 'json', { id }, customHeaders).pipe(
      map((response: HttpResponse <string>) => response.body),
      share()
    );
  }
}