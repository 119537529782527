import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpService } from '@oevermann/angular';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'app-email-multi-input',
  templateUrl: './email-multi-input.component.html'
})
export class EmailMultiInputComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() autocomplete: string;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() useMxLookup: boolean;

  constructor(private http: HttpService) {
  } 

  ngOnInit(): void {
    this.control.valueChanges.pipe(
      debounceTime(500))
      .subscribe(value => {
        if (value !== '') {
          const emails = encodeURI(value);
          const url = "/framework/api/emailvalidation/validateMultipleEMails";
          return this.http.get<boolean>(url, 'json', { 'emails': emails, 'useMxLookup': this.useMxLookup }).subscribe(resp => {
            if (!resp.body) {
              this.control.setErrors({ 'email': "Mindestens eine der eingegebenen E-Mail-Adressen ist ungültig" })
            }
          });
        }
      });
  }

}