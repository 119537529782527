import { Component, Input, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Base64File } from '../../generated/models';

@Component({
  selector: 'app-base64-file',
  templateUrl: './base64-file-input.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => Base64FileComponent),
    multi: true
  }]
})
export class Base64FileComponent implements ControlValueAccessor {

  private onChange = (file: Base64File | null) => { };
  private onTouched = () => { };

  file: Base64File | null = null;
  disabled: boolean;

  @Input() id: string;
  @Input() allowedFileTypes = "";
  @Input() required: boolean;

  @ViewChild('input', { static: false }) input: ElementRef;

  writeValue(file: Base64File | null): void {
    this.file = file;
  }

  registerOnChange(fn: (file: Base64File | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async change(event: Event) {
    const input = event.target as HTMLInputElement;
    this.file = await this.getBase64(input.files[0]);
    this.onChange(this.file);
    this.onTouched();
  }

  blur() {
    this.onTouched();
  }

  clear() {
    this.file = null;
    this.onChange(this.file);
    this.onTouched();
    const element = this.input.nativeElement as HTMLInputElement;
    try {
      element.type = 'text';
      element.type = 'file';
    } catch (error) { }

  }

  private getBase64(file: File): Promise<Base64File> {

    if (!file) {
      return Promise.resolve(undefined);
    }

    var reader = new FileReader();

    reader.readAsDataURL(file);

    return new Promise<Base64File>((resolve, reject) => {
      reader.onload = () => resolve({
        data: (reader.result as string).split(',')[1],
        filename: file.name,
        contentType: null
      });
      reader.onerror = reject;
    });

  }
}