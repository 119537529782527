import { Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule, LocatorService, LogModule, MemberAuthGuard, MemberModule, MemberTokenResolver, MemberTokenType, UiMessageServiceToken } from '@oevermann/angular';
import { Anis } from './anis';
import { ApiVersionChanged } from './app-version-changed';
import { AppComponent } from './app.component';
import { DatenschutzComponent } from './shared/page/datenschutz.component';
import { ImpressumComponent } from './shared/page/impressum.component';
import { NotFoundComponent } from './shared/not-found.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './shared/login/login.component';
import { PasswortZuruecksetzenComponent } from './shared/login/passwort-zuruecksetzen.component';
import { PasswortZuruecksetzenMitTokenComponent } from './shared/login/passwort-zuruecksetzen-mit-token.component';


const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				component: LoginComponent
			},
			{
				path: 'passwortzuruecksetzen',
				component: PasswortZuruecksetzenComponent
			},
			{
				path: 'passwortzuruecksetzenmittoken',
				component: PasswortZuruecksetzenMitTokenComponent,
				resolve: { token: MemberTokenResolver },
				data: { tokenType: MemberTokenType.ChangePassword }
			},
			{
				path: 'impressum',
				component: ImpressumComponent
			},
			{
				path: 'datenschutz',
				component: DatenschutzComponent
			},
			//{
			//	path: 'todo',
			//	loadChildren: () => import('./todo/todos.module').then(m => m.ToDosModule),
			//	canActivate: [MemberAuthGuard]
			//},
			{
				path: 'analyse',
				loadChildren: () => import('./analyse/analyse.module').then(m => m.AnalyseModule),
				canActivate: [MemberAuthGuard]
			},
			//{
			//	path: 'cockpit',
			//	loadChildren: () => import('./cockpit/cockpit.module').then(m => m.CockpitModule),
			//	canActivate: [MemberAuthGuard]
			//},
			{
				path: 'kunden',
				loadChildren: () => import('./kunden/kunden.module').then(m => m.KundenModule),
				canActivate: [MemberAuthGuard]
			},
			{
				path: 'verfahren',
				loadChildren: () => import('./kunden/verfahren/verfahren.module').then(m => m.VerfahrenModule),
				canActivate: [MemberAuthGuard]
			},
			{
				path: 'support',
				loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
				canActivate: [MemberAuthGuard]
			},
			{
				path: 'news',
				loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
				canActivate: [MemberAuthGuard]
			},
			{
				path: '**',
				component: NotFoundComponent
			}
		]
	}
]


@NgModule({
	declarations: [
		AppComponent,
		LoginComponent
	],
	imports: [
		BrowserAnimationsModule,
		ReactiveFormsModule,
		SharedModule,
		HttpModule.forRoot({
			apiVersion: Anis,
			apiVersionChanged: ApiVersionChanged
		}),
		MemberModule.forRoot(
			{
				loginPath: '/',
				logoutPath: '/'
			}),
		RouterModule.forRoot(routes),
		LogModule.forRoot()
	],
	providers: [
		{ provide: UiMessageServiceToken, useValue: null },
	],
	exports: [

	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(injector: Injector) {
		LocatorService.Injector = injector;
	}
}

