import { Component } from '@angular/core';

@Component({
  selector: 'app-datenschutz-detail',
  templateUrl: './datenschutz.component.html'
})
export class DatenschutzComponent {

  constructor() { }

}
