import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radiobutton-input',
  templateUrl: './radiobutton-input.component.html'
})
export class RadiobuttonInputComponent {

  @Input() id: string;
  @Input() groupname: string;
  @Input() label: string;
  @Input() control: FormControl;
  @Input() daten: { text: string, value: string | number }[] = [];

}