import { Component, Input } from '@angular/core';
import { TypedFormControl } from '../../generated/helpers';
import { Base64File } from '../../generated/models';

@Component({
  selector: 'app-upload-input',
  templateUrl: './upload-input.component.html'
})

export class UploadInputComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() control: TypedFormControl<Base64File>;
  @Input() allowedFileTypes = ".doc,.docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png";
  @Input() maxFileSize = 26214400; // 25 MB
  @Input() required: boolean; 
  @Input() infoText = "";
}