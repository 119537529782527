import { Injectable, Inject } from '@angular/core';
import { MemberService, HttpService, AuthServiceToken } from '@oevermann/angular';
import { map, switchMap } from 'rxjs/operators';
import { makeLegalFileName, saveOrOpenBlob } from '../helpers';
import { HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Injectable()
export class DownloadService {

  private subscription = new Subscription();

  constructor(@Inject(AuthServiceToken) private memberService: MemberService,
    private http: HttpService) {
  }

  downloadFile(blobId: number, fileName: string) {
    const url = "/api/Download/Get";
    this.subscription.add(this.memberService
      .getAccessToken()
      .pipe(
        map(token => new HttpHeaders({ 'Authorization': 'Bearer ' + token })),
        switchMap(headers => this.http.get<Blob>(url, 'blob', { 'id': blobId }, headers))
      ).subscribe(o => {
        const blob = o.body;
        saveOrOpenBlob(blob, makeLegalFileName(fileName));
      }));
  }

  async getFile(blobId: number) {
    const url = "/api/Download/Get";
    return this.memberService
      .getAccessToken()
      .pipe(
        map(token => new HttpHeaders({ 'Authorization': 'Bearer ' + token })),
        switchMap(headers => this.http.get<Blob>(url, 'blob', { 'id': blobId }, headers))
      ).toPromise();
  }

}