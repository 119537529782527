import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h1-output',
  templateUrl: './h1-output.component.html'
})
export class H1OutputComponent {

	@Input() text: string = '';

  constructor() { }
}
