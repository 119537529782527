import { Component } from '@angular/core';
import { Anis } from '../../anis';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html'
})
export class FooterComponent  {
	datum: Date = new Date();
	version = Anis;
}
