import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-radiobutton-output',
  templateUrl: './radiobutton-output.component.html'
})
export class RadiobuttonOutputComponent {

  @Input() id: string;
  @Input() groupname: string;
  @Input() label: string;
  @Input() value: boolean;
  @Input() daten: { text: string, value: string | number }[] = [];
}
