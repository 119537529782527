import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base.component';
import { IDDItem } from '../IDDItem';
import { LizenzStatus } from '../../generated/enums';

@Component({
  selector: 'app-lizenzstatus-dropdown-input',
  templateUrl: './lizenzstatus-dropdown-input.component.html'
})
export class LizenzStatusDropdownInputComponent extends BaseComponent implements OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() control: FormControl;
  @Input() required: boolean;
  @Input() extraItems: IDDItem[] = [];
  @Input() includeBitteWaehlen = true;
  @Input() hideLabel: boolean;

  public daten: IDDItem[];

  constructor() {
    super();
  }

  items = [
    //{ label: "Angelegt", value: LizenzStatus.Erstellt },
    { label: "Beantragt", value: LizenzStatus.Beantragt },
    { label: "Bestätigt", value: LizenzStatus.Bestaetigt },
    { label: "Deaktiviert", value: LizenzStatus.Deaktiviert }
  ];

  ngOnInit() {

    // bestätigt und deaktiviert kann nicht mehr auf beantragt gestellt werden
    if (this.control.value == LizenzStatus.Bestaetigt || this.control.value == LizenzStatus.Deaktiviert) {
      const index = this.items.findIndex(i => i.value == LizenzStatus.Beantragt);
      if (index > -1) {
        this.items.splice(index, 1);
      }
    }

    this.label = this.hideLabel ? '' : this.label;

    this.daten = this.includeBitteWaehlen ? [{ label: "Bitte wählen", value: null }] : [];
    this.daten = [...this.daten,
    ...this.extraItems,
    ...this.items.map(item => {
      return {
        label: item.label,
        value: item.value
      };
    })];
  }

}



