import { Component } from '@angular/core';

@Component({
  selector: 'app-impressum-detail',
  templateUrl: './impressum.component.html'
})
export class ImpressumComponent {

  constructor() { }

}
