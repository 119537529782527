import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MemberModule, MemberService } from '@oevermann/angular';
import { MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import {ApiFedDropdownInputComponent} from "./input/api-fed-dropdown-input.component";
import { ToastService } from './services/toast.service';
import { HeaderComponent } from './page/header.component';
import { MenuComponent } from './page/menu.component';
import { MainComponent } from './page/main.component';
import { FooterComponent } from './page/footer.component';
import { CheckboxInputComponent } from './input/checkbox-input.component';
import { DatepickerInputComponent } from './input/datepicker-input.component';
import { FormattedNumberInput } from './input/formatted-number-input.component';
import { DropdownInputComponent } from './input/dropdown-input.component';
import { NumberBoxInputComponent } from './input/numberbox-input.component';
import { RadiobuttonInputComponent } from './input/radiobutton-input.component';
import { TextareaInputComponent } from './input/textarea-input.component';
import { TextBoxInputComponent } from './input/textbox-input.component';
import { CheckboxOutputComponent } from './output/checkbox-output.component';
import { ErrorOutputComponent } from './output/error-output.component';
import { H1OutputComponent } from './output/h1-output.component';
import { H2OutputComponent } from './output/h2-output.component';
import { H3OutputComponent } from './output/h3-output.component';
import { H4OutputComponent } from './output/h4-output.component';
import { RadiobuttonOutputComponent } from './output/radiobutton-output.component';
import { TextareaOutputComponent } from './output/textarea-output.component';
import { TextOutputComponent } from './output/text-output.component';
import { KundeMultiSelectInputComponent } from './input/kunde-multiselect-input.component';
import { MultiSelectInputComponent } from './input/multiselect-input.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { EMailSchreibenComponent } from './email/email-schreiben.component';
import { EMailHttpService } from '../generated';
import { DateiHttpService } from '../generated';
import { DateiKategorieHttpService } from '../generated';
import { EmailMultiInputComponent } from './input/email-multi-input.component';
import { ExcludePipe } from './pipe/exclude.pipe';
import { NewsKategoriePipe } from './pipe/kategorie.pipe';
import { StatusPipe } from './pipe/status.pipe';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { UploadInputComponent } from './input/upload-input.component';
import { Base64FileComponent } from './input/base64-file-input.component';
import { Base64MultipleFileComponent } from './input/base64-multiple-file-input.component';
import { DatumPipe } from './pipe/datum.pipe';
import { SupportStatusDirective } from './directive/supportStatus.directive';
import { DownloadComponent } from './output/download.component';
import { DownloadService } from './services/download.service';
import { SubmenuComponent } from './page/submenu.component';
import { LizenzStatusDirective } from './directive/lizenzStatus.directive';
import { LizenzStatusPipe } from './pipe/lizenzStatus.pipe';
import { LizenzStatusDropdownInputComponent } from './input/lizenzstatus-dropdown-input.component';
import { IconBoolDirective } from './directive/icon-bool.directive';

const components = [
  HeaderComponent,
  MenuComponent,
  SubmenuComponent,
  MainComponent,
  FooterComponent,

  CheckboxInputComponent,
  DatepickerInputComponent,
  DropdownInputComponent,
  ApiFedDropdownInputComponent,
  MultiSelectInputComponent,
  KundeMultiSelectInputComponent,
  FormattedNumberInput,
  NumberBoxInputComponent,
  RadiobuttonInputComponent,
  TextareaInputComponent,
  TextBoxInputComponent,

  CheckboxOutputComponent,
  ErrorOutputComponent,
  H1OutputComponent,
  H2OutputComponent,
  H3OutputComponent,
  H4OutputComponent,
  RadiobuttonOutputComponent,
  TextareaOutputComponent,
  TextOutputComponent,

  EMailSchreibenComponent,
  EmailMultiInputComponent,
  UploadInputComponent,
  Base64FileComponent,
  Base64MultipleFileComponent,
  DownloadComponent,
  LizenzStatusDropdownInputComponent,

  ExcludePipe,
  NewsKategoriePipe,
  StatusPipe,
  DatumPipe,
  LizenzStatusPipe,

  SupportStatusDirective,
  LizenzStatusDirective,
  IconBoolDirective
];
@NgModule({
  declarations: components,
  providers: [
    ToastService,
    MessageService,
    MemberService,
    EMailHttpService,
    DateiHttpService,
    DateiKategorieHttpService,
    DownloadService,
    DialogService,
    DecimalPipe
  ],
  imports: [
    CommonModule,
    CalendarModule,
    DropdownModule,
    RouterModule,
    TableModule,
    ToastModule,
    MemberModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule,
    MultiSelectModule,
    DynamicDialogModule
  ],
  exports: [
    ...components,
    MemberModule,
    CommonModule,
    TableModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule
  ]
})
export class SharedModule { }
