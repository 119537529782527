import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html'
})
export class TextareaInputComponent implements OnInit {
  
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() control: FormControl;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() unit = "";
  @Input() infoText = "";
  @Input() maxLength = 0;

  currentLength = 0;

  constructor() { }

  ngOnInit() {
    if (this.maxLength > 0) {
      this.control.valueChanges.subscribe(value => {
        this.setLength(value);
      });
      this.setLength(this.control.value);
    }
  }

  setLength(value: string) {
    this.currentLength = value?.length;
    if (this.currentLength > this.maxLength) {
      this.control.setValue(value.substring(0, this.maxLength));
    }
  }

}
