import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Option} from "../../generated";
import {BaseComponent} from "../base.component";
import {IDDItem} from "../IDDItem";

@Component({
  selector: 'app-api-fed-dropdown-input',
  templateUrl: './api-fed-dropdown-input.component.html',
})
export class ApiFedDropdownInputComponent extends BaseComponent implements OnInit {

  @Input() public apiData$: Observable<Option[]>;
  @Input() public id: string;
  @Input() public label: string;
  @Input() public control: FormControl;
  @Input() public required: boolean;
  @Input() public extraItems: IDDItem[] | Option[] = [];
  @Input() public includeBitteWaehlen = true;
  @Input() public hideLabel: boolean;

  public data$: Observable<IDDItem[] | Option[]>;

  constructor() {
    super();
  }

  ngOnInit(): void {

    this.data$ = this.apiData$.pipe(map((data) => [...this.extraItems, ...data]));

    if (this.includeBitteWaehlen) {
      this.data$ = this.data$.pipe(map((data) => [new Option('bitte Wählen', null), ...data]))
    }

  }

}
