import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { markAsTouchedWithChildren, HttpService } from '@oevermann/angular';
import { BaseComponent } from '../base.component';
//import { BenutzerHttpService } from '../../generated/benutzer-http.service';
import { ERFOLG, FEHLER, HINWEIS } from '../consts';
import { EMailCreateTM, EMailDataContainerTM, DateiUpdateTM } from '../../generated/models';
import { EMailHttpService } from '../../generated/e-mail-http.service';
import { DateiHttpService } from '../../generated/datei-http.service';

@Component({
	selector: 'app-email-schreiben',
	templateUrl: './email-schreiben.component.html'
})
export class EMailSchreibenComponent extends BaseComponent implements OnInit {

	@Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

	form: EMailCreateTM.FormGroup;
	layerRef: DynamicDialogRef;

	constructor(private eMailHttpService: EMailHttpService,
		private httpService: HttpService,
		public ref: DynamicDialogRef,
		public config: DynamicDialogConfig,
		private toastService: ToastService,
		private dateiHttpService: DateiHttpService,
		private dialogService: DialogService,
		private cd: ChangeDetectorRef) {
		super();
	}

	async ngOnInit() {
		const emailData: EMailDataContainerTM = this.config.data.container;
		const model = await this.eMailHttpService.getDefault(emailData).toPromise();
		this.form = new EMailCreateTM.FormGroup(model);
	}

	close() {
		this.ref?.close();
	}

	async addAnhang() {
		const model = await this.dateiHttpService.getDefault(-1).toPromise();
		this.form.typed.controls.dateiColl.typed.push(new DateiUpdateTM.FormGroup(model));
	}

	removeAnhang(index: number) {
		const control = this.form.typed.controls.dateiColl.typed.controls[index];
		if (control.typed.value.id > 0) {
			control.markAsDeleted.typed.setValue(true);
		}
		else {
			this.form.typed.controls.dateiColl.removeAt(index);
		}
	}

	submit() {
		if (this.form.valid) {
			const request = this.form.model;
			this.subscription.add(this.eMailHttpService.save(request).subscribe(result => {
				if (result.id > 0) {
					this.toastService.showSuccess(ERFOLG, "Die E-Mail wurde gesendet.");
					this.close();
				}
				else {
					this.showError();
				}
			}, () => this.showError()));
		}
		else {
			this.toastService.showWarn(HINWEIS, "Fehlende Informationen_ Bitte �berpr�fen Sie Ihre Eingaben.");
			markAsTouchedWithChildren(this.form);
		}
	}

	private showError(): void {
		return this.toastService.showError(FEHLER, "Die E-Mail konnte nicht gesendet werden.");
	}
}
