import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-datepicker-input',
	templateUrl: './datepicker-input.component.html'
})
export class DatepickerInputComponent implements OnInit {

	de = {
		firstDayOfWeek: 1,
		dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
		dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
		dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
		monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
		monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
		today: 'Heute',
		clear: 'Leeren',
		dateFormat: 'dd.mm.yy',
		weekHeader: 'KW'
	};
	date: Date = new Date();

	@Input() id: string;
	@Input() label: string;
	@Input() placeholder: string;
	@Input() autocomplete: string;
	@Input() control: FormControl;
	@Input() disabledDays: number[] = [];
	@Input() readonlyInput: boolean = false;
	@Input() wrapperCssClass: string;
	@Input() timeOnly: boolean
	@Input() view: string = "date";
	@Input() yearRange: string;
	@Input() minDate: FormControl;
	@Input() maxDate: FormControl;
	@Input() required: boolean;
	@Input() cssClass: string = "";
	@Input() dateFormat = this.de.dateFormat;

	form: FormControl;

	constructor(private config: PrimeNGConfig) { }

	ngOnInit() {
		this.setDefaultValues();

		this.form = new FormControl(this.control.value != null ? new Date(this.control.value) : '');
		this.form.valueChanges.subscribe(value => {
			if (value) {
				const date = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), value.getSeconds()));
				this.control.setValue(date.toISOString());
			}
		});

		this.config.setTranslation({
			dayNames: this.de.dayNames,
			dayNamesShort: this.de.dayNamesShort,
			dayNamesMin: this.de.dayNamesMin,
			monthNames: this.de.monthNames,
			monthNamesShort: this.de.monthNamesShort,
			today: this.de.today,
			clear: this.de.clear,
			weekHeader: this.de.weekHeader
		});
	}

	setDefaultValues() {
		if (!this.yearRange || this.yearRange == "") {
			this.yearRange = this.getYearRange();
		}
	}

	getYearRange() {
		let date = new Date();
		return date.getUTCFullYear().toString() + ":2050";
	}

	removeValue() {
		this.control.setValue('');
	}

	getMinDate() {
		return this.minDate != null && this.minDate.value != null && this.minDate.value != '' ? new Date(this.minDate.value) : null;
	}

	getMaxDate() {
		return this.maxDate != null && this.maxDate.value != null && this.maxDate.value != '' ? new Date(this.maxDate.value) : null;
	}

	setTouched(event: Event) {
		this.control.markAsTouched(); 
	}

}
