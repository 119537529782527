import { Injectable } from "@angular/core";
import { ListService } from "./list.service";
import { PagedResult, NewsListRequestTM, NewsListItemTM } from "./models";
import { Observable } from "rxjs";
import { NewsHttpService } from "./news-http.service";

const defaults = { ...NewsListRequestTM.empty(), page: 1, pageSize: 100, sort: null, sortOrder: 0 };

@Injectable()
export class NewsListService extends ListService<NewsListRequestTM, NewsListRequestTM.FormGroup, NewsListItemTM> {

  resultChanges: Observable<PagedResult<NewsListItemTM>>;
  resultFullPageChanges: Observable<PagedResult<NewsListItemTM>>;
  form: NewsListRequestTM.FormGroup;

  get result(): PagedResult<NewsListItemTM> {
    return super.result as PagedResult<NewsListItemTM>;
  }

  constructor(private modelHttpService: NewsHttpService) {
    super(defaults);
  }

  protected getDebounceTime() {
    return 300;
  }

  protected createForm(request: NewsListRequestTM) {
    return new NewsListRequestTM.FormGroup(request);
  }

  protected load(request: NewsListRequestTM) {
    return this.modelHttpService.list(request);
  }

  public reset() {
    this.form.reset(defaults);
  }
}