import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MemberService } from '@oevermann/angular';
import { EMailTyp, SortOrder } from '../generated/enums';


export function getLayerTitle(typ?: EMailTyp) {
  switch (typ) {
    case 1:
      return "E-Mail antworten";
    case 2:
      return "E-Mail allen antworten";
    case 3:
      return "E-Mail weiterleiten";
    case 4:
      return "Neue E-Mail schreiben";
    default:
      return "E-Mail schreiben";
  }
}


export function getDefaultPath(memberService: MemberService, returnUrl: string) {
  if (returnUrl) {
    return returnUrl
  }

  if (memberService.isInRole('Admin')) {
    return '/kunden';
  }
  return '/logout';
}


export function setSort(listService: any, sort: string) {
  if (listService.form.sort.typed.value === sort) {
    const currentSortOrder = listService.form.typed.value.sortOrder;
    listService.form.typed.patchValue({
      sortOrder: currentSortOrder === SortOrder.Unspecified
        ? SortOrder.Ascending
        : currentSortOrder === SortOrder.Ascending
          ? SortOrder.Descending
          : SortOrder.Ascending
    });
  }
  else {
    listService.form.typed.patchValue({
      sort,
      sortOrder: SortOrder.Ascending
    });
  }
}


export function initFixTableHead() {
  //window.setTimeout(function () { fixTableHead('fixTableHead') }, 1000); // 1500
  setTimeout(() => {
    const target = document.querySelector(".fixHead thead");
    if (target) {
      const observer = new IntersectionObserver(callback, {threshold: [0, 1]});
      observer.observe(target);
    }
  }, 2000);
}


function callback(entries, observer) {
  entries.forEach((entry) => {
    if (entry.intersectionRatio < 1) {
      entry.target.classList.add("fixed");
    }
    else if (entry.intersectionRatio == 1) {
      entry.target.classList.remove("fixed");
    }
  });
}


export function setUnsetValidator(control: AbstractControl, validator: ValidatorFn = null) {
  control.setValidators(validator);
  control.markAsUntouched();
  doUpdateValueAndValidity(control);
}


export function doUpdateValueAndValidity(control: AbstractControl) {
  control.updateValueAndValidity({ onlySelf: false, emitEvent: false });
}


export function makeLegalFileName(value) {
  return value.replace(new RegExp(/[\(\[\:\\\/\*\?\|\<\>\]\)\ ]/g), "_");
}


export function saveOrOpenBlob(blob, filename) {
  if ((navigator as unknown as any).msSaveOrOpenBlob) {
    (navigator as unknown as any).msSaveOrOpenBlob(blob, filename);
  }
  else {
    const url = URL.createObjectURL(blob);
    downloadURL(url, filename);
  }
}


function downloadURL(url, filename) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  if (document.createEvent) {
    var event = document.createEvent("MouseEvents");
    event.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(event);
  }
  else {
    a.click();
  }
  document.body.removeChild(a);
}


// erstmal nur eine Notlösung 
export function getDateString() {
  var date = new Date();
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2);
}


export function getReplacedText(text: string, replacings: { [key: string]: string }) {
  for (let key in replacings) {
    let val = replacings[key];
    text = text.replace(new RegExp(key, 'g'), val);
  }
  return text;
}


export function addButtonWaitAnimation(event: Event, disable: boolean) {
  let button = (event.target as HTMLButtonElement);
  button.disabled = disable;
  disable ? button.classList.add("wait") : button.classList.remove("wait");
}


// workaround to clear displayed value from Datepicker
export function setDatePickerValue(id: string, value: string) {
  document.getElementById(id).getElementsByTagName('input')[0].value = value;
}
