import { PipeTransform, Pipe } from '@angular/core';
import { LizenzStatus } from '../../generated/enums';

@Pipe({
  name: 'lizenzStatus',
  pure: false
})
export class LizenzStatusPipe implements PipeTransform {
  transform(value: LizenzStatus) {
    switch (value) {
      case LizenzStatus.Erstellt:
        return "Erstellt";
      case LizenzStatus.Beantragt:
        return "Beantragt";
      case LizenzStatus.Bestaetigt:
        return "Bestätigt";
      case LizenzStatus.Deaktiviert:
        return "Deaktiviert";
      default:
        return "Unbekannt";
    }
  }
}
