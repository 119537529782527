import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
	selector: 'app-formatted-number-input',
	templateUrl: './formatted-number-input.component.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: FormattedNumberInput,
		multi: true
	}]
})
export class FormattedNumberInput implements ControlValueAccessor {

	@Input() id: string;
	@Input() autocomplete: string;
	@Input() readonly: boolean;
	@Input() required: boolean;
	@Input() numberPattern = '1.2-2';
	@Input() unit = "";
	@Input() infoText = "";
	@Input() isDisabled = false;
	groupSeparator: string;
	groupSepRegEx: RegExp;
	decimalSeparator: string;
	decimalSepRegEx: RegExp;
	groupsRegEx = new RegExp("(\\d)(?=(?:\\d{3})+\\b)", "g");

	public _value: string = '0';

	hasFocus: boolean = false;

	private onChangeFn: (value: number) => void = () => { };
	private onTouchFn: () => void = () => { };

	constructor(private numberPipe: DecimalPipe) {
		this.groupSeparator = this.getSeparator('de-DE', 'group');
		this.groupSepRegEx = new RegExp("\\" + this.groupSeparator, "g");
		this.decimalSeparator = this.getSeparator('de-DE', 'decimal');
		this.decimalSepRegEx = new RegExp("\\" + this.decimalSeparator, "g");
	}

	writeValue(value: any): void {
		let formatted = this.numberPipe.transform(value, this.numberPattern, 'de-DE');
		formatted = formatted ? formatted : '0';
		this.value = formatted.replace(this.groupSepRegEx, '');
	}

	registerOnChange(fn: (value: number) => void): void {
		this.onChangeFn = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchFn = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	get value(): string {
		return this.hasFocus ? this._value : this.formatValue(this._value);
	}
	set value(val: string) {

		if (val == null || val.length == 0) {
			this.onChangeFn(null);
		}
		else if (new RegExp("^-?\\d+(" + this.decimalSeparator + "\\d+)?$").test(val)) {
			this.onChangeFn(parseFloat(val.replace(this.groupSepRegEx, '').replace(this.decimalSepRegEx, '.')));
			this._value = val;
		}
		else {
			this.onChangeFn(NaN);
		}
		this.onTouchFn();
	}

	focus() {
		this.hasFocus = true;
	}

	blur() {
		this.hasFocus = false;
		this.onTouchFn();
	}

	formatValue(val: string | null) {
		let parts = (val ? val : this.value).split(this.decimalSeparator);
		parts[0] = parts[0].replace(this.groupsRegEx, "$1" + this.groupSeparator);
		if (parts.length == 1) {
			return parts[0];
		}
		else {
			return parts[0] + this.decimalSeparator + parts[1];
		}
	}

	getSeparator(locale: string, type: string) {
		let separators = (1111.1).toLocaleString(locale).replace(/1/g, '');
		if (type == 'group') {
			return separators.substr(0, 1);
		}
		if (separators.length > 1) {
			return separators.substr(1, 1);
		}
		return '';
	}

}