import { Injectable } from "@angular/core";
import { ListService } from "./list.service";
import { PagedResult, SupportListRequestTM, TicketTm } from "./models";
import { Observable } from "rxjs";
import { SupportHttpService } from "./support-http.service";

const defaults = { ...SupportListRequestTM.empty(), page: 1, pageSize: 100, sort: 'Betreff', sortOrder: 0 };

@Injectable()
export class SupportListService extends ListService<SupportListRequestTM, SupportListRequestTM.FormGroup, TicketTm> {

  resultChanges: Observable<PagedResult<TicketTm>>;
  resultFullPageChanges: Observable<PagedResult<TicketTm>>;
  form: SupportListRequestTM.FormGroup;

  get result(): PagedResult<TicketTm> {
    return super.result as PagedResult<TicketTm>;
  }

  constructor(private modelHttpService: SupportHttpService) {
    super(defaults);
  }

  protected getDebounceTime() {
    return 300;
  }

  protected createForm(request: SupportListRequestTM) {
    return new SupportListRequestTM.FormGroup(request);
  }

  protected load(request: SupportListRequestTM) {
    return this.modelHttpService.list(request);
  }

  public reset() {
    this.form.reset(defaults);
  }
}