import { Component, OnInit, Inject } from '@angular/core';
import { MemberService, AuthServiceToken, MemberLoginBaseComponent } from '@oevermann/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { getDefaultPath } from '../helpers';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends MemberLoginBaseComponent implements OnInit {

  constructor(private router: Router,
    private route: ActivatedRoute,
    @Inject(AuthServiceToken) protected memberService: MemberService) {
    super(memberService);
    this.passwordForm.setValidators(Validators.required);
  }

  get returnUrl() {
    return this.route.snapshot.queryParams.returnUrl as string;
  }

  async ngOnInit() {
    if (this.memberService.isAuthenticated()) {
      const url = getDefaultPath(this.memberService, this.returnUrl);
      this.router.navigateByUrl(url);
    }
    super.ngOnInit();
  }

  async loggedIn() {
    const url = getDefaultPath(this.memberService, this.returnUrl);
    this.router.navigateByUrl(url);
  }

}
