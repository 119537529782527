import { Component } from '@angular/core';
import { MemberRecoverPasswordBaseComponent, MemberService } from '@oevermann/angular';
import { Router } from '@angular/router';


@Component({
  selector: 'app-passwort-zuruecksetzen',
  templateUrl: './passwort-zuruecksetzen.component.html'
})
export class PasswortZuruecksetzenComponent extends MemberRecoverPasswordBaseComponent {

  constructor(private router: Router,
    protected memberService: MemberService) {
    super(memberService);
  }

  cancel(event: Event) {
    event && event.preventDefault();
    this.router.navigate(['/']);
  }

  passwordRecovered(): void { }

}