
export enum SortOrder {
  Ascending = 0,
  Descending = 1,
  Unspecified = -1
}

export enum NewsKategorie {
  None = 0,
  NeuerBenutzer = 1,
  Feature = 2,
  Aktuelles = 3,
  Tipps = 4
}

export enum LizenzStatus {
  None = 0,
  Erstellt = 1,
  Beantragt = 2,
  Bestaetigt = 3,
  Deaktiviert = 4
}

export enum SupportStatus {
  NichtErmittelbar = 0,
  Offen = 1,
  OnHold = 2,
  InArbeit = 3,
  Erledigt = 4,
  FehlerTicketSystem = 5
}

export enum EMailTyp {
  unbekannt = 0,
  antworten = 1,
  allenAntworten = 2,
  weiterleiten = 3,
  neueMail = 4
}

export enum BounceStatus {
  Hard_Bounce = 0,
  Soft_Bounce = 1,
  General_Bounce_With_Email = 2,
  General_Bounce_Without_Email = 3,
  Mail_Block = 4,
  Auto_Reply = 5,
  Transient_Message = 6,
  Subscribe_Request = 7,
  Unsubscribe_Request = 8,
  Virus_Notification = 9,
  Suspected_Bounce_Without_Other_Info = 10,
  Challenge_Response = 11,
  Mail_Changed = 12,
  Success = 13,
  Other = 14,
  Email_Invalid = 15,
  Domain_not_found = 16,
  None = -1
}

export enum MailVerzeichnis {
  Posteingang = 0,
  System = 1,
  Sonstiges = 2,
  Support = 3
}
