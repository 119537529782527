import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {SupportStatus} from '../../generated';

@Directive({
	selector: '[supportStatus]'
})
export class SupportStatusDirective implements OnInit {

	private _status: SupportStatus;
	@Input('supportStatus') set status(value: SupportStatus) {
		this._status = value;
		this.check();
	}

	constructor(private elem: ElementRef,
		private renderer: Renderer2) { }

	ngOnInit() {
		this.check();
	}

	check() {

		['red', 'blue', 'yellow', 'green', 'magenta', 'cyan'].forEach((farbe) => this.renderer.removeClass(this.elem.nativeElement, farbe));

		let colorClass: string;

		switch (this._status) {
			case SupportStatus.Offen:
				colorClass = 'red';
				break;
			case SupportStatus.OnHold:
				colorClass = 'blue';
				break;
			case SupportStatus.InArbeit:
				colorClass = 'yellow';
				break;
			case SupportStatus.Erledigt:
				colorClass = 'green';
				break;
			case SupportStatus.NichtErmittelbar:
				colorClass = 'cyan';
				break;
			case SupportStatus.FehlerTicketSystem:
				colorClass = 'magenta';
		}

		if (colorClass) {
			this.renderer.addClass(this.elem.nativeElement, colorClass);
		}
	}

}
