import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'datum',
  pure: false
})
export class DatumPipe implements PipeTransform {

  private datePipe = new DatePipe('de');

  constructor() {  }

  transform(value: Date | string | number): string {
    value = this.datePipe.transform(value, "yyyy-MM-dd\THH:mm:ss");
    const myValue = value ? value.replace('Z', '') : null;
    const val = new Date(myValue).getTime();
    const now = new Date().getTime();
    const diff = Math.abs(((now - val) / 1000) / 86400);

    if (diff < 30) {
      return this.getLesbaresDatum(myValue);
    }
    else {
      return this.getDatumUhrzeit(myValue);
    }
  }

  getLesbaresDatum(value: string) {
    let date = new Date(value),
      today = new Date(),
      yesterday = (function (d) { d.setDate(d.getDate() - 1); return d })(new Date),
      diff = Math.floor((+today - +date) / 1000),
      daydiff = Math.floor(diff / 86400);

    if (isNaN(daydiff) || daydiff < 0) {
      daydiff = 0;
    }
    if (daydiff >= 31) {
      return '';
    }

    return daydiff == 0 && (
      date.toDateString() == today.toDateString() && "Heute" + this.getUhrzeit(value)) ||
      date.toDateString() == yesterday.toDateString() && "Gestern" + this.getUhrzeit(value) ||
      daydiff < 7 && this.getWochentag(value) + this.getUhrzeit(value) ||
      this.getDatumUhrzeit(value);
  }

  getWochentag(value: string) {
    return this.datePipe.transform(value, 'EEEE');
  }

  getUhrzeit(value: string) {
    return this.datePipe.transform(value, '\' um \'HH:mm');
  }

  getDatumUhrzeit(value: string) {
    return this.datePipe.transform(value, 'dd.MM.yyyy\' um \'HH:mm');
  }
}


