import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {Option} from "../../generated";
import { IDDItem } from '../IDDItem';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html'
})
export class DropdownInputComponent {

  @Input() id: string;
  @Input() label: string;
  @Input() daten: IDDItem[] | Option[];
  @Input() control: FormControl;
  @Input() filterBy = '';
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() disabled: boolean;

  constructor() { }

  getLabel() {
    const filtered: IDDItem[] | Option[] = this.daten ? this.daten.filter(d => d.value == this.control.value) : [];
    return filtered[0] ? filtered[0].label : '';
  }

}
