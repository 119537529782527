import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { NewsTM, NewsListRequestTM, PagedResult, NewsListItemTM } from "./models";
import { map, share } from "rxjs/operators";

@Injectable()
export class NewsHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public get(id: number, customHeaders?: HttpHeaders) {
    return this.httpService.get<NewsTM>(URI`/api/News/Get`, 'json', { id }, customHeaders).pipe(
      map((response: HttpResponse <NewsTM>) => response.body),
      share()
    );
  }
  
  public list(request: NewsListRequestTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<PagedResult<NewsListItemTM>>(URI`/api/News/List`, 'json', request, customHeaders).pipe(
      map((response: HttpResponse <PagedResult<NewsListItemTM>>) => response.body),
      share()
    );
  }
  
  public save(model: NewsTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<NewsTM>(URI`/api/News/Save`, 'json', model, customHeaders).pipe(
      map((response: HttpResponse <NewsTM>) => response.body),
      share()
    );
  }
}