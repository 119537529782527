import { Directive, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';

@Directive({
	selector: '[iconBool]'
})
export class IconBoolDirective implements OnInit {

	private _status: boolean;
	@Input('iconBool') set status(value) {
		this._status = value;
		this.check();
	}

	constructor(private elem: ElementRef,
		private renderer: Renderer2) { }

	ngOnInit() {
		this.check();
	}

	check() {
		this.renderer.removeClass(this.elem.nativeElement, 'yellow');
		this.renderer.removeClass(this.elem.nativeElement, 'green');
		this.renderer.removeClass(this.elem.nativeElement, 'red');
		if (this._status == null) {
			this.renderer.addClass(this.elem.nativeElement, 'yellow');
		}
		else if (!!this._status) {
			this.renderer.addClass(this.elem.nativeElement, 'green');
		}
		else if (!this._status) {
			this.renderer.addClass(this.elem.nativeElement, 'red');
		}
	}

}