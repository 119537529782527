import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html'
})
export class CheckboxInputComponent {

  @Input() id: string;
  @Input() name: string;
  @Input() label: string;
  @Input() control: FormControl;
  @Input() labelAfterBox: boolean = true;
  @Input() title: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  @Input() infoText: string = "";

  constructor() { }

  isDisabled() {
    return this.control && this.control.disabled ? true : this.disabled ? true : null;
  }

}
