import { Directive, ElementRef, OnInit, Input, Renderer2 } from '@angular/core';
import { LizenzStatus } from '../../generated/enums';

@Directive({
	selector: '[lizenzStatus]'
})
export class LizenzStatusDirective implements OnInit {

	private _status: LizenzStatus;
	@Input('lizenzStatus') set status(value) {
		this._status = value;
		this.check();
	}

	constructor(private elem: ElementRef,
		private renderer: Renderer2) { }

	ngOnInit() {
		this.check();
	}

	check() {
		this.renderer.removeClass(this.elem.nativeElement, 'blue');
		this.renderer.removeClass(this.elem.nativeElement, 'yellow');
		this.renderer.removeClass(this.elem.nativeElement, 'green');
		this.renderer.removeClass(this.elem.nativeElement, 'red');
		if (this._status == LizenzStatus.Erstellt) {
			this.renderer.addClass(this.elem.nativeElement, 'blue');
		}
		if (this._status == LizenzStatus.Beantragt) {
			this.renderer.addClass(this.elem.nativeElement, 'yellow');
		}
		else if (this._status == LizenzStatus.Bestaetigt) {
			this.renderer.addClass(this.elem.nativeElement, 'green');
		}
		else if (this._status == LizenzStatus.Deaktiviert) {
			this.renderer.addClass(this.elem.nativeElement, 'red');
		}
	}

}