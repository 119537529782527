import { OnDestroy } from '@angular/core';
import { AuthServiceToken, LocatorService, MemberService } from '@oevermann/angular';
import { Subject, Subscription } from 'rxjs';

export class BaseComponent implements OnDestroy {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  protected subscription = new Subscription();
  public memberService: MemberService;

  constructor() {
    this.memberService = LocatorService.Injector.get(AuthServiceToken) as MemberService;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscription.unsubscribe();
  }

}
