import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { VerfahrenRequestTM, PagedResult, VerfahrenListItemTM } from "./models";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, share } from "rxjs/operators";

@Injectable()
export class VerfahrenHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public list(request: VerfahrenRequestTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<PagedResult<VerfahrenListItemTM>>(URI`/api/Verfahren/List`, 'json', request, customHeaders).pipe(
      map((response: HttpResponse <PagedResult<VerfahrenListItemTM>>) => response.body),
      share()
    );
  }
}