import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-numberbox-input',
  templateUrl: './numberbox-input.component.html'
})
export class NumberBoxInputComponent {

  @Input() id: string;
  @Input() label: string;
  @Input() control: FormControl;
  @Input() autocomplete: string;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() numberPattern: string = '1.2-2';
  @Input() unit: string = "";
  @Input() infoText: string = "";
  @Input() isDisabled = false;

}
