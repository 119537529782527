import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { AnalyseRequestTM, AnalyseTM } from "./models";
import { map, share } from "rxjs/operators";

@Injectable()
export class AnalyseHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public getDefaultFilter(customHeaders?: HttpHeaders) {
    return this.httpService.get<AnalyseRequestTM>(URI`/api/Analyse/GetDefaultFilter`, 'json', customHeaders).pipe(
      map((response: HttpResponse <AnalyseRequestTM>) => response.body),
      share()
    );
  }
  
  public get(request: AnalyseRequestTM, customHeaders?: HttpHeaders) {
    return this.httpService.post<AnalyseTM>(URI`/api/Analyse/Get`, 'json', request, customHeaders).pipe(
      map((response: HttpResponse <AnalyseTM>) => response.body),
      share()
    );
  }
}