import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-h3-output',
  templateUrl: './h3-output.component.html'
})
export class H3OutputComponent {
  @Input() text: string;

  constructor() { }
}
