import { Component } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  title = 'AdiSmart';
  loading = true;

  constructor(private router: Router) {
    this.router.events.subscribe((event: Event) => {
      const loadingDiv = document.getElementById('loading');
      if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        loadingDiv.classList.add('loading-hidden');
        this.loading = false;
      }
      registerLocaleData(localeDe, 'de');
    });
  }

}
