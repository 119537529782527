import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IDDItem } from '../IDDItem';

@Component({
  selector: 'app-multiselect-input',
  templateUrl: './multiselect-input.component.html'
})
export class MultiSelectInputComponent implements OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() daten: IDDItem[];
  @Input() control: FormControl;
  @Input() filterBy = '';
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() selectedItemsLabel: string;

 
  constructor() {
  }

  ngOnInit() {
  }

}
