import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable()
export class ToastService {
  constructor(private messageService: MessageService) { }

  showSuccess(text: string, detail: string) {
    const toast = this.getBaseToast(text, detail);
    this.messageService.add({ severity: 'success', ...toast });
  }

  showError(text: string, detail: string) {
    const toast = this.getBaseToast(text, detail);
    this.messageService.add({ severity: 'error', ...toast });
  }

  showWarn(text: string, detail: string) {
    const toast = this.getBaseToast(text, detail);
    this.messageService.add({ severity: 'warn', ...toast });
  }

  showInfo(text: string, detail: string) {
    const toast = this.getBaseToast(text, detail);
    this.messageService.add({ severity: 'info', ...toast });
  }

  private getBaseToast(text: string, detail: string) {
    return { summary: text, detail: detail, life: 3500, closable: false };
  }
}