import { Injectable } from "@angular/core";
import { ListService } from "./list.service";
import { PagedResult, KundeListRequestTM, KundeListItemTM } from "./models";
import { Observable } from "rxjs";
import { KundeHttpService } from "./kunde-http.service";

const defaults = { ...KundeListRequestTM.empty(), page: 1, pageSize: 100, sort: null, sortOrder: 0 };

@Injectable()
export class KundeListService extends ListService<KundeListRequestTM, KundeListRequestTM.FormGroup, KundeListItemTM> {

  resultChanges: Observable<PagedResult<KundeListItemTM>>;
  resultFullPageChanges: Observable<PagedResult<KundeListItemTM>>;
  form: KundeListRequestTM.FormGroup;

  get result(): PagedResult<KundeListItemTM> {
    return super.result as PagedResult<KundeListItemTM>;
  }

  constructor(private modelHttpService: KundeHttpService) {
    super(defaults);
  }

  protected getDebounceTime() {
    return 300;
  }

  protected createForm(request: KundeListRequestTM) {
    return new KundeListRequestTM.FormGroup(request);
  }

  protected load(request: KundeListRequestTM) {
    return this.modelHttpService.list(request);
  }

  public reset() {
    this.form.reset(defaults);
  }
}