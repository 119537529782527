import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-output',
  templateUrl: './text-output.component.html'
})
export class TextOutputComponent {

  @Input() label: string;
  @Input() value: string;
  @Input() sign: string = '';
  @Input() unit: string = '';
  @Input() isNumeric: boolean;
  @Input() numberPattern: string = '1.2-2';
  @Input() isDate: boolean;
  @Input() datePattern: string = 'dd.MM.yyyy, HH:mm:ss';
  @Input() wrap: boolean;
  @Input() flat: boolean;
  @Input() additional: string;
  @Input() infoText: string = "";

  constructor() { }

}
