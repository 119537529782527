import { PipeTransform, Pipe } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'exclude',
  pure: false
})
export class ExcludePipe implements PipeTransform {

  transform(value: AbstractControl[], option: 'deleted') {

    if (option !== 'deleted') {
      throw new Error('Eine andere Option als \'deleted\' wird derzeit nicht unterst�tzt.');
    }

    return value.filter(control => !control || !control.value || !control.value.markAsDeleted);
  }
}


