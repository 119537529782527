import { ResourceProvider } from './resource-provider';
import { Injectable } from '@angular/core';

@Injectable()
export class DefaultResourceProvider implements ResourceProvider {
  private resources = {
    'dialog.discard.header': 'Ungespeicherte Änderungen',
    'dialog.discard.contentCanSave': 'Sie haben noch ungespeicherte Änderungen, diese können in ihrem aktuellen Zustand nicht gespeichert werden.',
    'dialog.discard.content': 'Sie haben noch ungespeicherte Änderungen, sollen diese gespeichert werden?',
    'dialog.delete.header': 'Eintrag löschen?',
    'dialog.delete.content': 'Soll der Eintrag wirklich gelöscht werden?',
    'labels.save': 'Speichern',
    'labels.cancel': 'Abbrechen',
    'labels.discardChanges': 'Änderungen verwerfen',
    'labels.yes': 'Ja',
    'labels.no': 'Nein',
    'errors.deleteNotSupported': 'Delete wird nicht unterstützt.',
    'errors.invalidForm.title': 'Formular ungültig.',
    'errors.invalidForm.text': 'Bitte prüfen Sie ihre Eingaben.',
  };

  getResource(key: string) {
    return this.resources[key];
  }

}
