import { Injectable, Inject } from "@angular/core";
import { UiMessageService, UiMessageServiceToken, HttpService, URI } from "@oevermann/angular";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { DateiUpdateTM } from "./models";
import { map, share } from "rxjs/operators";

@Injectable()
export class DateiHttpService {
  
  constructor(private httpService: HttpService, @Inject(UiMessageServiceToken) private messageService: UiMessageService) { }
  
  public getDatei(dateiId: number, customHeaders?: HttpHeaders) {
    return this.httpService.get<DateiUpdateTM>(URI`/api/Datei/GetDatei`, 'json', { dateiId }, customHeaders).pipe(
      map((response: HttpResponse <DateiUpdateTM>) => response.body),
      share()
    );
  }
  
  public getDefault(kundeId: number, customHeaders?: HttpHeaders) {
    return this.httpService.get<DateiUpdateTM>(URI`/api/Datei/GetDefault`, 'json', { kundeId }, customHeaders).pipe(
      map((response: HttpResponse <DateiUpdateTM>) => response.body),
      share()
    );
  }
}