import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberChangePasswordBaseComponent, MemberService } from '@oevermann/angular';

@Component({
  selector: 'app-passwort-zuruecksetzen-mit-token',
  templateUrl: './passwort-zuruecksetzen-mit-token.component.html',
})
export class PasswortZuruecksetzenMitTokenComponent extends MemberChangePasswordBaseComponent {

  constructor(protected memberService: MemberService,
    private route: ActivatedRoute,
    private router: Router) {
    super(memberService);
  }

  get token() {
    return this.route.snapshot.data.token as string;
  }

  passwordChanged() { }

}