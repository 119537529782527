import { TMValue, PropertyRights, TypedFormControl, TypedFormGroup, TypedFormArray, TypedFormDictionary, mapValues } from "./helpers";
import { ValidatorGetter, OnValidators, SanitizeValidator, OnHttpAsyncValidator, CollectionLengthValidator, RequiredIfValidator } from "@oevermann/angular";
import { ValidatorFn, AsyncValidatorFn, Validators } from "@angular/forms";
import { SortOrder, NewsKategorie, LizenzStatus, SupportStatus, EMailTyp, BounceStatus, MailVerzeichnis } from "./enums";

export interface KundeTM {
  id: number;
  bezeichnung: TMValue<string>;
  url_Intern: TMValue<string>;
  url_Extern: TMValue<string>;
  version: TMValue<number>;
  strasse: TMValue<string>;
  ansprechpartnerEmail: TMValue<string>;
  ansprechpartnerName: TMValue<string>;
  rechnungsEmail: TMValue<string>;
  ansprechpartnerTelefon: TMValue<string>;
  bic: TMValue<string>;
  iban: TMValue<string>;
  kontoinhaber: TMValue<string>;
  kreditInstitut: TMValue<string>;
  land: TMValue<string>;
  ort: TMValue<string>;
  plz: TMValue<string>;
  markAsDeleted: boolean;
}

export namespace KundeTM {

  export function empty(): KundeTM {
    return {
      id: 0,
      bezeichnung: { right: PropertyRights.All, value: null },
      url_Intern: { right: PropertyRights.All, value: null },
      url_Extern: { right: PropertyRights.All, value: null },
      version: { right: PropertyRights.All, value: null },
      strasse: { right: PropertyRights.All, value: null },
      ansprechpartnerEmail: { right: PropertyRights.All, value: null },
      ansprechpartnerName: { right: PropertyRights.All, value: null },
      rechnungsEmail: { right: PropertyRights.All, value: null },
      ansprechpartnerTelefon: { right: PropertyRights.All, value: null },
      bic: { right: PropertyRights.All, value: null },
      iban: { right: PropertyRights.All, value: null },
      kontoinhaber: { right: PropertyRights.All, value: null },
      kreditInstitut: { right: PropertyRights.All, value: null },
      land: { right: PropertyRights.All, value: null },
      ort: { right: PropertyRights.All, value: null },
      plz: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    bezeichnung: string | null;
    url_Intern: string | null;
    url_Extern: string | null;
    version: number;
    strasse: string | null;
    ansprechpartnerEmail: string | null;
    ansprechpartnerName: string | null;
    rechnungsEmail: string | null;
    ansprechpartnerTelefon: string | null;
    bic: string | null;
    iban: string | null;
    kontoinhaber: string | null;
    kreditInstitut: string | null;
    land: string | null;
    ort: string | null;
    plz: string | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    bezeichnung: TypedFormControl<string>;
    url_Intern: TypedFormControl<string>;
    url_Extern: TypedFormControl<string>;
    version: TypedFormControl<number>;
    strasse: TypedFormControl<string>;
    ansprechpartnerEmail: TypedFormControl<string>;
    ansprechpartnerName: TypedFormControl<string>;
    rechnungsEmail: TypedFormControl<string>;
    ansprechpartnerTelefon: TypedFormControl<string>;
    bic: TypedFormControl<string>;
    iban: TypedFormControl<string>;
    kontoinhaber: TypedFormControl<string>;
    kreditInstitut: TypedFormControl<string>;
    land: TypedFormControl<string>;
    ort: TypedFormControl<string>;
    plz: TypedFormControl<string>;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<KundeTM.Value, Controls> {
    
    private _model: KundeTM;
    
    public get originalModel(): KundeTM {
      return this._model;
    }
    
    constructor(model: KundeTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.bezeichnung != null) {
        const control = new TypedFormControl<string>(model.bezeichnung.value, Validators.compose([OnValidators.required]));
        control.right = model.bezeichnung.right;
        this.addControl('bezeichnung', control);
      }
      
      if(model.url_Intern != null) {
        const control = new TypedFormControl<string>(model.url_Intern.value, null);
        control.right = model.url_Intern.right;
        this.addControl('url_Intern', control);
      }
      
      if(model.url_Extern != null) {
        const control = new TypedFormControl<string>(model.url_Extern.value, null);
        control.right = model.url_Extern.right;
        this.addControl('url_Extern', control);
      }
      
      if(model.version != null) {
        const control = new TypedFormControl<number>(model.version.value, Validators.compose([OnValidators.required]));
        control.right = model.version.right;
        this.addControl('version', control);
      }
      
      if(model.strasse != null) {
        const control = new TypedFormControl<string>(model.strasse.value, null);
        control.right = model.strasse.right;
        this.addControl('strasse', control);
      }
      
      if(model.ansprechpartnerEmail != null) {
        const control = new TypedFormControl<string>(model.ansprechpartnerEmail.value, null);
        control.right = model.ansprechpartnerEmail.right;
        this.addControl('ansprechpartnerEmail', control);
      }
      
      if(model.ansprechpartnerName != null) {
        const control = new TypedFormControl<string>(model.ansprechpartnerName.value, null);
        control.right = model.ansprechpartnerName.right;
        this.addControl('ansprechpartnerName', control);
      }
      
      if(model.rechnungsEmail != null) {
        const control = new TypedFormControl<string>(model.rechnungsEmail.value, null);
        control.right = model.rechnungsEmail.right;
        this.addControl('rechnungsEmail', control);
      }
      
      if(model.ansprechpartnerTelefon != null) {
        const control = new TypedFormControl<string>(model.ansprechpartnerTelefon.value, null);
        control.right = model.ansprechpartnerTelefon.right;
        this.addControl('ansprechpartnerTelefon', control);
      }
      
      if(model.bic != null) {
        const control = new TypedFormControl<string>(model.bic.value, null);
        control.right = model.bic.right;
        this.addControl('bic', control);
      }
      
      if(model.iban != null) {
        const control = new TypedFormControl<string>(model.iban.value, null);
        control.right = model.iban.right;
        this.addControl('iban', control);
      }
      
      if(model.kontoinhaber != null) {
        const control = new TypedFormControl<string>(model.kontoinhaber.value, null);
        control.right = model.kontoinhaber.right;
        this.addControl('kontoinhaber', control);
      }
      
      if(model.kreditInstitut != null) {
        const control = new TypedFormControl<string>(model.kreditInstitut.value, null);
        control.right = model.kreditInstitut.right;
        this.addControl('kreditInstitut', control);
      }
      
      if(model.land != null) {
        const control = new TypedFormControl<string>(model.land.value, null);
        control.right = model.land.right;
        this.addControl('land', control);
      }
      
      if(model.ort != null) {
        const control = new TypedFormControl<string>(model.ort.value, null);
        control.right = model.ort.right;
        this.addControl('ort', control);
      }
      
      if(model.plz != null) {
        const control = new TypedFormControl<string>(model.plz.value, null);
        control.right = model.plz.right;
        this.addControl('plz', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get url_Intern() {
      return this.controls.url_Intern as any as TypedFormControl<string>;
    }
    
    get url_Extern() {
      return this.controls.url_Extern as any as TypedFormControl<string>;
    }
    
    get version() {
      return this.controls.version as any as TypedFormControl<number>;
    }
    
    get strasse() {
      return this.controls.strasse as any as TypedFormControl<string>;
    }
    
    get ansprechpartnerEmail() {
      return this.controls.ansprechpartnerEmail as any as TypedFormControl<string>;
    }
    
    get ansprechpartnerName() {
      return this.controls.ansprechpartnerName as any as TypedFormControl<string>;
    }
    
    get rechnungsEmail() {
      return this.controls.rechnungsEmail as any as TypedFormControl<string>;
    }
    
    get ansprechpartnerTelefon() {
      return this.controls.ansprechpartnerTelefon as any as TypedFormControl<string>;
    }
    
    get bic() {
      return this.controls.bic as any as TypedFormControl<string>;
    }
    
    get iban() {
      return this.controls.iban as any as TypedFormControl<string>;
    }
    
    get kontoinhaber() {
      return this.controls.kontoinhaber as any as TypedFormControl<string>;
    }
    
    get kreditInstitut() {
      return this.controls.kreditInstitut as any as TypedFormControl<string>;
    }
    
    get land() {
      return this.controls.land as any as TypedFormControl<string>;
    }
    
    get ort() {
      return this.controls.ort as any as TypedFormControl<string>;
    }
    
    get plz() {
      return this.controls.plz as any as TypedFormControl<string>;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    get model(): KundeTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        url_Intern: this.typed.controls.url_Intern != undefined ? {
          value: this.typed.controls.url_Intern.model,
          right: this.typed.controls.url_Intern.right,
        } : undefined,
        url_Extern: this.typed.controls.url_Extern != undefined ? {
          value: this.typed.controls.url_Extern.model,
          right: this.typed.controls.url_Extern.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        strasse: this.typed.controls.strasse != undefined ? {
          value: this.typed.controls.strasse.model,
          right: this.typed.controls.strasse.right,
        } : undefined,
        ansprechpartnerEmail: this.typed.controls.ansprechpartnerEmail != undefined ? {
          value: this.typed.controls.ansprechpartnerEmail.model,
          right: this.typed.controls.ansprechpartnerEmail.right,
        } : undefined,
        ansprechpartnerName: this.typed.controls.ansprechpartnerName != undefined ? {
          value: this.typed.controls.ansprechpartnerName.model,
          right: this.typed.controls.ansprechpartnerName.right,
        } : undefined,
        rechnungsEmail: this.typed.controls.rechnungsEmail != undefined ? {
          value: this.typed.controls.rechnungsEmail.model,
          right: this.typed.controls.rechnungsEmail.right,
        } : undefined,
        ansprechpartnerTelefon: this.typed.controls.ansprechpartnerTelefon != undefined ? {
          value: this.typed.controls.ansprechpartnerTelefon.model,
          right: this.typed.controls.ansprechpartnerTelefon.right,
        } : undefined,
        bic: this.typed.controls.bic != undefined ? {
          value: this.typed.controls.bic.model,
          right: this.typed.controls.bic.right,
        } : undefined,
        iban: this.typed.controls.iban != undefined ? {
          value: this.typed.controls.iban.model,
          right: this.typed.controls.iban.right,
        } : undefined,
        kontoinhaber: this.typed.controls.kontoinhaber != undefined ? {
          value: this.typed.controls.kontoinhaber.model,
          right: this.typed.controls.kontoinhaber.right,
        } : undefined,
        kreditInstitut: this.typed.controls.kreditInstitut != undefined ? {
          value: this.typed.controls.kreditInstitut.model,
          right: this.typed.controls.kreditInstitut.right,
        } : undefined,
        land: this.typed.controls.land != undefined ? {
          value: this.typed.controls.land.model,
          right: this.typed.controls.land.right,
        } : undefined,
        ort: this.typed.controls.ort != undefined ? {
          value: this.typed.controls.ort.model,
          right: this.typed.controls.ort.right,
        } : undefined,
        plz: this.typed.controls.plz != undefined ? {
          value: this.typed.controls.plz.model,
          right: this.typed.controls.plz.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): KundeTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        url_Intern: this.typed.controls.url_Intern != undefined ? {
          value: this.typed.controls.url_Intern.model,
          right: this.typed.controls.url_Intern.right,
        } : undefined,
        url_Extern: this.typed.controls.url_Extern != undefined ? {
          value: this.typed.controls.url_Extern.model,
          right: this.typed.controls.url_Extern.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        strasse: this.typed.controls.strasse != undefined ? {
          value: this.typed.controls.strasse.model,
          right: this.typed.controls.strasse.right,
        } : undefined,
        ansprechpartnerEmail: this.typed.controls.ansprechpartnerEmail != undefined ? {
          value: this.typed.controls.ansprechpartnerEmail.model,
          right: this.typed.controls.ansprechpartnerEmail.right,
        } : undefined,
        ansprechpartnerName: this.typed.controls.ansprechpartnerName != undefined ? {
          value: this.typed.controls.ansprechpartnerName.model,
          right: this.typed.controls.ansprechpartnerName.right,
        } : undefined,
        rechnungsEmail: this.typed.controls.rechnungsEmail != undefined ? {
          value: this.typed.controls.rechnungsEmail.model,
          right: this.typed.controls.rechnungsEmail.right,
        } : undefined,
        ansprechpartnerTelefon: this.typed.controls.ansprechpartnerTelefon != undefined ? {
          value: this.typed.controls.ansprechpartnerTelefon.model,
          right: this.typed.controls.ansprechpartnerTelefon.right,
        } : undefined,
        bic: this.typed.controls.bic != undefined ? {
          value: this.typed.controls.bic.model,
          right: this.typed.controls.bic.right,
        } : undefined,
        iban: this.typed.controls.iban != undefined ? {
          value: this.typed.controls.iban.model,
          right: this.typed.controls.iban.right,
        } : undefined,
        kontoinhaber: this.typed.controls.kontoinhaber != undefined ? {
          value: this.typed.controls.kontoinhaber.model,
          right: this.typed.controls.kontoinhaber.right,
        } : undefined,
        kreditInstitut: this.typed.controls.kreditInstitut != undefined ? {
          value: this.typed.controls.kreditInstitut.model,
          right: this.typed.controls.kreditInstitut.right,
        } : undefined,
        land: this.typed.controls.land != undefined ? {
          value: this.typed.controls.land.model,
          right: this.typed.controls.land.right,
        } : undefined,
        ort: this.typed.controls.ort != undefined ? {
          value: this.typed.controls.ort.model,
          right: this.typed.controls.ort.right,
        } : undefined,
        plz: this.typed.controls.plz != undefined ? {
          value: this.typed.controls.plz.model,
          right: this.typed.controls.plz.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<KundeTM.Value, KundeTM.FormGroup> {
    
    constructor(model?: KundeTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new KundeTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as KundeTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<KundeTM.Value, KundeTM.FormGroup> {
    
    constructor(model?: { [key: string]: KundeTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new KundeTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface KundeDropDownItemTM {
  id: number;
  bezeichnung: TMValue<string>;
  markAsDeleted: boolean;
}

export namespace KundeDropDownItemTM {

  export function empty(): KundeDropDownItemTM {
    return {
      id: 0,
      bezeichnung: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    bezeichnung: string | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    bezeichnung: TypedFormControl<string>;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<KundeDropDownItemTM.Value, Controls> {
    
    private _model: KundeDropDownItemTM;
    
    public get originalModel(): KundeDropDownItemTM {
      return this._model;
    }
    
    constructor(model: KundeDropDownItemTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.bezeichnung != null) {
        const control = new TypedFormControl<string>(model.bezeichnung.value, Validators.compose([OnValidators.required]));
        control.right = model.bezeichnung.right;
        this.addControl('bezeichnung', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    get model(): KundeDropDownItemTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): KundeDropDownItemTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<KundeDropDownItemTM.Value, KundeDropDownItemTM.FormGroup> {
    
    constructor(model?: KundeDropDownItemTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new KundeDropDownItemTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as KundeDropDownItemTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<KundeDropDownItemTM.Value, KundeDropDownItemTM.FormGroup> {
    
    constructor(model?: { [key: string]: KundeDropDownItemTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new KundeDropDownItemTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface KundeListRequestTM {
  bezeichnung?: string | null;
  page: number;
  pageSize: number;
  sort?: string | null;
  sortOrder: SortOrder;
}

export namespace KundeListRequestTM {

  export function empty(): KundeListRequestTM {
    return {
      bezeichnung: null,
      page: 0,
      pageSize: 0,
      sort: null,
      sortOrder: -1,
    };
  }
  
  export interface Value {
    bezeichnung: string | null;
    page: number;
    pageSize: number;
    sort: string | null;
    sortOrder: SortOrder;
  }
  
  interface Controls {
    bezeichnung: TypedFormControl<string>;
    page: TypedFormControl<number>;
    pageSize: TypedFormControl<number>;
    sort: TypedFormControl<string>;
    sortOrder: TypedFormControl<SortOrder>;
  }
  
  export class FormGroup extends TypedFormGroup<KundeListRequestTM.Value, Controls> {
    
    private _model: KundeListRequestTM;
    
    public get originalModel(): KundeListRequestTM {
      return this._model;
    }
    
    constructor(model: KundeListRequestTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('bezeichnung', new TypedFormControl<string>(model.bezeichnung, null));
      
      this.addControl('page', new TypedFormControl<number>(model.page, Validators.compose([OnValidators.required])));
      
      this.addControl('pageSize', new TypedFormControl<number>(model.pageSize, Validators.compose([OnValidators.required])));
      
      this.addControl('sort', new TypedFormControl<string>(model.sort, null));
      
      this.addControl('sortOrder', new TypedFormControl<SortOrder>(model.sortOrder, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get page() {
      return this.controls.page as any as TypedFormControl<number>;
    }
    
    get pageSize() {
      return this.controls.pageSize as any as TypedFormControl<number>;
    }
    
    get sort() {
      return this.controls.sort as any as TypedFormControl<string>;
    }
    
    get sortOrder() {
      return this.controls.sortOrder as any as TypedFormControl<SortOrder>;
    }
    
    get model(): KundeListRequestTM {
      return {
        bezeichnung: this.typed.controls.bezeichnung?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
    
    getRawValue(): KundeListRequestTM {
      return {
        bezeichnung: this.typed.controls.bezeichnung?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<KundeListRequestTM.Value, KundeListRequestTM.FormGroup> {
    
    constructor(model?: KundeListRequestTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new KundeListRequestTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as KundeListRequestTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<KundeListRequestTM.Value, KundeListRequestTM.FormGroup> {
    
    constructor(model?: { [key: string]: KundeListRequestTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new KundeListRequestTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface PagedResult<T> {
  page: number;
  pageSize: number;
  totalCount: number;
  startIndex: number;
  endIndex: number;
  count: number;
  totalPages: number;
  canPageForward: boolean;
  canPageBackward: boolean;
  items?: T[] | null;
}

export namespace PagedResult {

  export function empty<T>(): PagedResult<T> {
    return {
      page: 0,
      pageSize: 0,
      totalCount: 0,
      startIndex: 0,
      endIndex: 0,
      count: 0,
      totalPages: 0,
      canPageForward: false,
      canPageBackward: false,
      items: [],
    };
  }
  
  export interface Value<T> {
    page: number;
    pageSize: number;
    totalCount: number;
    startIndex: number;
    endIndex: number;
    count: number;
    totalPages: number;
    canPageForward: boolean;
    canPageBackward: boolean;
    items: T[] | null;
  }
  
  interface Controls<T> {
    page: TypedFormControl<number>;
    pageSize: TypedFormControl<number>;
    totalCount: TypedFormControl<number>;
    startIndex: TypedFormControl<number>;
    endIndex: TypedFormControl<number>;
    count: TypedFormControl<number>;
    totalPages: TypedFormControl<number>;
    canPageForward: TypedFormControl<boolean>;
    canPageBackward: TypedFormControl<boolean>;
    items: TypedFormControl<T[]>;
  }
  
  export class FormGroup<T> extends TypedFormGroup<PagedResult<T>, Controls<T>> {
    
    private _model: PagedResult<T>;
    
    public get originalModel(): PagedResult<T> {
      return this._model;
    }
    
    constructor(model: PagedResult<T>, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('page', new TypedFormControl<number>(model.page, null));
      
      this.addControl('pageSize', new TypedFormControl<number>(model.pageSize, null));
      
      this.addControl('totalCount', new TypedFormControl<number>(model.totalCount, null));
      
      this.addControl('startIndex', new TypedFormControl<number>(model.startIndex, null));
      
      this.addControl('endIndex', new TypedFormControl<number>(model.endIndex, null));
      
      this.addControl('count', new TypedFormControl<number>(model.count, null));
      
      this.addControl('totalPages', new TypedFormControl<number>(model.totalPages, null));
      
      this.addControl('canPageForward', new TypedFormControl<boolean>(model.canPageForward, null));
      
      this.addControl('canPageBackward', new TypedFormControl<boolean>(model.canPageBackward, null));
      
      this.addControl('items', new TypedFormControl<T[]>(model.items || [], null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get page() {
      return this.controls.page as any as TypedFormControl<number>;
    }
    
    get pageSize() {
      return this.controls.pageSize as any as TypedFormControl<number>;
    }
    
    get totalCount() {
      return this.controls.totalCount as any as TypedFormControl<number>;
    }
    
    get startIndex() {
      return this.controls.startIndex as any as TypedFormControl<number>;
    }
    
    get endIndex() {
      return this.controls.endIndex as any as TypedFormControl<number>;
    }
    
    get count() {
      return this.controls.count as any as TypedFormControl<number>;
    }
    
    get totalPages() {
      return this.controls.totalPages as any as TypedFormControl<number>;
    }
    
    get canPageForward() {
      return this.controls.canPageForward as any as TypedFormControl<boolean>;
    }
    
    get canPageBackward() {
      return this.controls.canPageBackward as any as TypedFormControl<boolean>;
    }
    
    get items() {
      return this.controls.items as any as TypedFormControl<T[]>;
    }
    
    get model(): PagedResult<T> {
      return {
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        totalCount: this.typed.controls.totalCount?.model,
        startIndex: this.typed.controls.startIndex?.model,
        endIndex: this.typed.controls.endIndex?.model,
        count: this.typed.controls.count?.model,
        totalPages: this.typed.controls.totalPages?.model,
        canPageForward: this.typed.controls.canPageForward?.model,
        canPageBackward: this.typed.controls.canPageBackward?.model,
        items: this.typed.controls.items?.model,
      };
    }
    
    getRawValue(): PagedResult<T> {
      return {
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        totalCount: this.typed.controls.totalCount?.model,
        startIndex: this.typed.controls.startIndex?.model,
        endIndex: this.typed.controls.endIndex?.model,
        count: this.typed.controls.count?.model,
        totalPages: this.typed.controls.totalPages?.model,
        canPageForward: this.typed.controls.canPageForward?.model,
        canPageBackward: this.typed.controls.canPageBackward?.model,
        items: this.typed.controls.items?.model,
      };
    }
  }
  
  export class FormArray<T> extends TypedFormArray<PagedResult.Value<T>, PagedResult.FormGroup<T>> {
    
    constructor(model?: PagedResult<T>[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new PagedResult.FormGroup<T>(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as PagedResult.FormGroup<T>[];
    }
  }
  
  export class FormDictionary<T> extends TypedFormDictionary<PagedResult.Value<T>, PagedResult.FormGroup<T>> {
    
    constructor(model?: { [key: string]: PagedResult<T> }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new PagedResult.FormGroup<T>(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface KundeListItemTM {
  id: number;
  bezeichnung: TMValue<string>;
  url_Intern: TMValue<string>;
  url_Extern: TMValue<string>;
  markAsDeleted: boolean;
}

export namespace KundeListItemTM {

  export function empty(): KundeListItemTM {
    return {
      id: 0,
      bezeichnung: { right: PropertyRights.All, value: null },
      url_Intern: { right: PropertyRights.All, value: null },
      url_Extern: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    bezeichnung: string | null;
    url_Intern: string | null;
    url_Extern: string | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    bezeichnung: TypedFormControl<string>;
    url_Intern: TypedFormControl<string>;
    url_Extern: TypedFormControl<string>;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<KundeListItemTM.Value, Controls> {
    
    private _model: KundeListItemTM;
    
    public get originalModel(): KundeListItemTM {
      return this._model;
    }
    
    constructor(model: KundeListItemTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.bezeichnung != null) {
        const control = new TypedFormControl<string>(model.bezeichnung.value, Validators.compose([OnValidators.required]));
        control.right = model.bezeichnung.right;
        this.addControl('bezeichnung', control);
      }
      
      if(model.url_Intern != null) {
        const control = new TypedFormControl<string>(model.url_Intern.value, null);
        control.right = model.url_Intern.right;
        this.addControl('url_Intern', control);
      }
      
      if(model.url_Extern != null) {
        const control = new TypedFormControl<string>(model.url_Extern.value, null);
        control.right = model.url_Extern.right;
        this.addControl('url_Extern', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get url_Intern() {
      return this.controls.url_Intern as any as TypedFormControl<string>;
    }
    
    get url_Extern() {
      return this.controls.url_Extern as any as TypedFormControl<string>;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    get model(): KundeListItemTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        url_Intern: this.typed.controls.url_Intern != undefined ? {
          value: this.typed.controls.url_Intern.model,
          right: this.typed.controls.url_Intern.right,
        } : undefined,
        url_Extern: this.typed.controls.url_Extern != undefined ? {
          value: this.typed.controls.url_Extern.model,
          right: this.typed.controls.url_Extern.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): KundeListItemTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        url_Intern: this.typed.controls.url_Intern != undefined ? {
          value: this.typed.controls.url_Intern.model,
          right: this.typed.controls.url_Intern.right,
        } : undefined,
        url_Extern: this.typed.controls.url_Extern != undefined ? {
          value: this.typed.controls.url_Extern.model,
          right: this.typed.controls.url_Extern.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<KundeListItemTM.Value, KundeListItemTM.FormGroup> {
    
    constructor(model?: KundeListItemTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new KundeListItemTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as KundeListItemTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<KundeListItemTM.Value, KundeListItemTM.FormGroup> {
    
    constructor(model?: { [key: string]: KundeListItemTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new KundeListItemTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface AnalyseRequestTM {
  kunden?: number[] | null;
  verfahrenFilter?: VerfahrenFilterTM | null;
}

export namespace AnalyseRequestTM {

  export function empty(): AnalyseRequestTM {
    return {
      kunden: [],
      verfahrenFilter: VerfahrenFilterTM.empty(),
    };
  }
  
  export interface Value {
    kunden: number[] | null;
    verfahrenFilter: VerfahrenFilterTM.Value | null;
  }
  
  interface Controls {
    kunden: TypedFormControl<number[]>;
    verfahrenFilter: VerfahrenFilterTM.FormGroup;
  }
  
  export class FormGroup extends TypedFormGroup<AnalyseRequestTM.Value, Controls> {
    
    private _model: AnalyseRequestTM;
    
    public get originalModel(): AnalyseRequestTM {
      return this._model;
    }
    
    constructor(model: AnalyseRequestTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('kunden', new TypedFormControl<number[]>(model.kunden || [], null));
      
      if(model.verfahrenFilter != null) { this.addControl('verfahrenFilter', new VerfahrenFilterTM.FormGroup(model.verfahrenFilter, null)); }
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get kunden() {
      return this.controls.kunden as any as TypedFormControl<number[]>;
    }
    
    get verfahrenFilter() {
      return this.controls.verfahrenFilter as any as VerfahrenFilterTM.FormGroup;
    }
    
    get model(): AnalyseRequestTM {
      return {
        kunden: this.typed.controls.kunden?.model,
        verfahrenFilter: this.typed.controls.verfahrenFilter?.model,
      };
    }
    
    getRawValue(): AnalyseRequestTM {
      return {
        kunden: this.typed.controls.kunden?.model,
        verfahrenFilter: this.typed.controls.verfahrenFilter?.getRawValue(),
      };
    }
  }
  
  export class FormArray extends TypedFormArray<AnalyseRequestTM.Value, AnalyseRequestTM.FormGroup> {
    
    constructor(model?: AnalyseRequestTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new AnalyseRequestTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as AnalyseRequestTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<AnalyseRequestTM.Value, AnalyseRequestTM.FormGroup> {
    
    constructor(model?: { [key: string]: AnalyseRequestTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new AnalyseRequestTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface VerfahrenFilterTM {
  verfahrensArtEV: boolean;
  verfahrensArtRegelIV: boolean;
  aktiveVerfahren: boolean;
  abgeschlosseneVerfahren: boolean;
  geloeschteVerfahren: boolean;
  mitBestellungen: boolean;
  mitLieferanten: boolean;
  von?: string | null;
  bis?: string | null;
}

export namespace VerfahrenFilterTM {

  export function empty(): VerfahrenFilterTM {
    return {
      verfahrensArtEV: false,
      verfahrensArtRegelIV: false,
      aktiveVerfahren: false,
      abgeschlosseneVerfahren: false,
      geloeschteVerfahren: false,
      mitBestellungen: false,
      mitLieferanten: false,
      von: null,
      bis: null,
    };
  }
  
  export interface Value {
    verfahrensArtEV: boolean;
    verfahrensArtRegelIV: boolean;
    aktiveVerfahren: boolean;
    abgeschlosseneVerfahren: boolean;
    geloeschteVerfahren: boolean;
    mitBestellungen: boolean;
    mitLieferanten: boolean;
    von: string | null;
    bis: string | null;
  }
  
  interface Controls {
    verfahrensArtEV: TypedFormControl<boolean>;
    verfahrensArtRegelIV: TypedFormControl<boolean>;
    aktiveVerfahren: TypedFormControl<boolean>;
    abgeschlosseneVerfahren: TypedFormControl<boolean>;
    geloeschteVerfahren: TypedFormControl<boolean>;
    mitBestellungen: TypedFormControl<boolean>;
    mitLieferanten: TypedFormControl<boolean>;
    von: TypedFormControl<string>;
    bis: TypedFormControl<string>;
  }
  
  export class FormGroup extends TypedFormGroup<VerfahrenFilterTM.Value, Controls> {
    
    private _model: VerfahrenFilterTM;
    
    public get originalModel(): VerfahrenFilterTM {
      return this._model;
    }
    
    constructor(model: VerfahrenFilterTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('verfahrensArtEV', new TypedFormControl<boolean>(model.verfahrensArtEV, null));
      
      this.addControl('verfahrensArtRegelIV', new TypedFormControl<boolean>(model.verfahrensArtRegelIV, null));
      
      this.addControl('aktiveVerfahren', new TypedFormControl<boolean>(model.aktiveVerfahren, null));
      
      this.addControl('abgeschlosseneVerfahren', new TypedFormControl<boolean>(model.abgeschlosseneVerfahren, null));
      
      this.addControl('geloeschteVerfahren', new TypedFormControl<boolean>(model.geloeschteVerfahren, null));
      
      this.addControl('mitBestellungen', new TypedFormControl<boolean>(model.mitBestellungen, null));
      
      this.addControl('mitLieferanten', new TypedFormControl<boolean>(model.mitLieferanten, null));
      
      this.addControl('von', new TypedFormControl<string>(model.von, null));
      
      this.addControl('bis', new TypedFormControl<string>(model.bis, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get verfahrensArtEV() {
      return this.controls.verfahrensArtEV as any as TypedFormControl<boolean>;
    }
    
    get verfahrensArtRegelIV() {
      return this.controls.verfahrensArtRegelIV as any as TypedFormControl<boolean>;
    }
    
    get aktiveVerfahren() {
      return this.controls.aktiveVerfahren as any as TypedFormControl<boolean>;
    }
    
    get abgeschlosseneVerfahren() {
      return this.controls.abgeschlosseneVerfahren as any as TypedFormControl<boolean>;
    }
    
    get geloeschteVerfahren() {
      return this.controls.geloeschteVerfahren as any as TypedFormControl<boolean>;
    }
    
    get mitBestellungen() {
      return this.controls.mitBestellungen as any as TypedFormControl<boolean>;
    }
    
    get mitLieferanten() {
      return this.controls.mitLieferanten as any as TypedFormControl<boolean>;
    }
    
    get von() {
      return this.controls.von as any as TypedFormControl<string>;
    }
    
    get bis() {
      return this.controls.bis as any as TypedFormControl<string>;
    }
    
    get model(): VerfahrenFilterTM {
      return {
        verfahrensArtEV: this.typed.controls.verfahrensArtEV?.model,
        verfahrensArtRegelIV: this.typed.controls.verfahrensArtRegelIV?.model,
        aktiveVerfahren: this.typed.controls.aktiveVerfahren?.model,
        abgeschlosseneVerfahren: this.typed.controls.abgeschlosseneVerfahren?.model,
        geloeschteVerfahren: this.typed.controls.geloeschteVerfahren?.model,
        mitBestellungen: this.typed.controls.mitBestellungen?.model,
        mitLieferanten: this.typed.controls.mitLieferanten?.model,
        von: this.typed.controls.von?.model,
        bis: this.typed.controls.bis?.model,
      };
    }
    
    getRawValue(): VerfahrenFilterTM {
      return {
        verfahrensArtEV: this.typed.controls.verfahrensArtEV?.model,
        verfahrensArtRegelIV: this.typed.controls.verfahrensArtRegelIV?.model,
        aktiveVerfahren: this.typed.controls.aktiveVerfahren?.model,
        abgeschlosseneVerfahren: this.typed.controls.abgeschlosseneVerfahren?.model,
        geloeschteVerfahren: this.typed.controls.geloeschteVerfahren?.model,
        mitBestellungen: this.typed.controls.mitBestellungen?.model,
        mitLieferanten: this.typed.controls.mitLieferanten?.model,
        von: this.typed.controls.von?.model,
        bis: this.typed.controls.bis?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<VerfahrenFilterTM.Value, VerfahrenFilterTM.FormGroup> {
    
    constructor(model?: VerfahrenFilterTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new VerfahrenFilterTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as VerfahrenFilterTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<VerfahrenFilterTM.Value, VerfahrenFilterTM.FormGroup> {
    
    constructor(model?: { [key: string]: VerfahrenFilterTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new VerfahrenFilterTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface AnalyseTM {
  verfahrenKennzahlen?: VerfahrenKennzahlenTM | null;
  buZLKennzahlen?: BuZLKennzahlenTM | null;
  evKennzahlen?: EVKennzahlenTM | null;
  sachwalterKennzahlen?: SachwalterKennzahlenTM | null;
}

export interface VerfahrenKennzahlenTM {
  rows?: AnalyseRowTM[] | null;
}

export interface AnalyseRowTM {
  bezeichnung?: string | null;
  cells?: AnalyseCellTM[] | null;
}

export interface AnalyseCellTM {
  bezeichnung?: string | null;
  value: number;
}

export interface BuZLKennzahlenTM {
  rows?: AnalyseRowTM[] | null;
}

export interface EVKennzahlenTM {
  rows?: AnalyseRowTM[] | null;
}

export interface SachwalterKennzahlenTM {
  rows?: AnalyseRowTM[] | null;
}

export interface NewsTM {
  id: number;
  bezeichnung: TMValue<string>;
  text: TMValue<string>;
  detail: TMValue<string>;
  kategorie: TMValue<NewsKategorie>;
  aktiv: TMValue<boolean>;
  version: TMValue<number>;
  markAsDeleted: boolean;
}

export namespace NewsTM {

  export function empty(): NewsTM {
    return {
      id: 0,
      bezeichnung: { right: PropertyRights.All, value: null },
      text: { right: PropertyRights.All, value: null },
      detail: { right: PropertyRights.All, value: null },
      kategorie: { right: PropertyRights.All, value: null },
      aktiv: { right: PropertyRights.All, value: null },
      version: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    bezeichnung: string | null;
    text: string | null;
    detail: string | null;
    kategorie: NewsKategorie;
    aktiv: boolean;
    version: number | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    bezeichnung: TypedFormControl<string>;
    text: TypedFormControl<string>;
    detail: TypedFormControl<string>;
    kategorie: TypedFormControl<NewsKategorie>;
    aktiv: TypedFormControl<boolean>;
    version: TypedFormControl<number>;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<NewsTM.Value, Controls> {
    
    private _model: NewsTM;
    
    public get originalModel(): NewsTM {
      return this._model;
    }
    
    constructor(model: NewsTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.bezeichnung != null) {
        const control = new TypedFormControl<string>(model.bezeichnung.value, Validators.compose([OnValidators.required]));
        control.right = model.bezeichnung.right;
        this.addControl('bezeichnung', control);
      }
      
      if(model.text != null) {
        const control = new TypedFormControl<string>(model.text.value, null);
        control.right = model.text.right;
        this.addControl('text', control);
      }
      
      if(model.detail != null) {
        const control = new TypedFormControl<string>(model.detail.value, Validators.compose([OnValidators.required]));
        control.right = model.detail.right;
        this.addControl('detail', control);
      }
      
      if(model.kategorie != null) {
        const control = new TypedFormControl<NewsKategorie>(model.kategorie.value, Validators.compose([OnValidators.required]));
        control.right = model.kategorie.right;
        this.addControl('kategorie', control);
      }
      
      if(model.aktiv != null) {
        const control = new TypedFormControl<boolean>(model.aktiv.value, null);
        control.right = model.aktiv.right;
        this.addControl('aktiv', control);
      }
      
      if(model.version != null) {
        const control = new TypedFormControl<number>(model.version.value, null);
        control.right = model.version.right;
        this.addControl('version', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get text() {
      return this.controls.text as any as TypedFormControl<string>;
    }
    
    get detail() {
      return this.controls.detail as any as TypedFormControl<string>;
    }
    
    get kategorie() {
      return this.controls.kategorie as any as TypedFormControl<NewsKategorie>;
    }
    
    get aktiv() {
      return this.controls.aktiv as any as TypedFormControl<boolean>;
    }
    
    get version() {
      return this.controls.version as any as TypedFormControl<number>;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    get model(): NewsTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        text: this.typed.controls.text != undefined ? {
          value: this.typed.controls.text.model,
          right: this.typed.controls.text.right,
        } : undefined,
        detail: this.typed.controls.detail != undefined ? {
          value: this.typed.controls.detail.model,
          right: this.typed.controls.detail.right,
        } : undefined,
        kategorie: this.typed.controls.kategorie != undefined ? {
          value: this.typed.controls.kategorie.model,
          right: this.typed.controls.kategorie.right,
        } : undefined,
        aktiv: this.typed.controls.aktiv != undefined ? {
          value: this.typed.controls.aktiv.model,
          right: this.typed.controls.aktiv.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): NewsTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        text: this.typed.controls.text != undefined ? {
          value: this.typed.controls.text.model,
          right: this.typed.controls.text.right,
        } : undefined,
        detail: this.typed.controls.detail != undefined ? {
          value: this.typed.controls.detail.model,
          right: this.typed.controls.detail.right,
        } : undefined,
        kategorie: this.typed.controls.kategorie != undefined ? {
          value: this.typed.controls.kategorie.model,
          right: this.typed.controls.kategorie.right,
        } : undefined,
        aktiv: this.typed.controls.aktiv != undefined ? {
          value: this.typed.controls.aktiv.model,
          right: this.typed.controls.aktiv.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<NewsTM.Value, NewsTM.FormGroup> {
    
    constructor(model?: NewsTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new NewsTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as NewsTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<NewsTM.Value, NewsTM.FormGroup> {
    
    constructor(model?: { [key: string]: NewsTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new NewsTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface NewsListRequestTM {
  bezeichnung?: string | null;
  page: number;
  pageSize: number;
  sort?: string | null;
  sortOrder: SortOrder;
}

export namespace NewsListRequestTM {

  export function empty(): NewsListRequestTM {
    return {
      bezeichnung: null,
      page: 0,
      pageSize: 0,
      sort: null,
      sortOrder: -1,
    };
  }
  
  export interface Value {
    bezeichnung: string | null;
    page: number;
    pageSize: number;
    sort: string | null;
    sortOrder: SortOrder;
  }
  
  interface Controls {
    bezeichnung: TypedFormControl<string>;
    page: TypedFormControl<number>;
    pageSize: TypedFormControl<number>;
    sort: TypedFormControl<string>;
    sortOrder: TypedFormControl<SortOrder>;
  }
  
  export class FormGroup extends TypedFormGroup<NewsListRequestTM.Value, Controls> {
    
    private _model: NewsListRequestTM;
    
    public get originalModel(): NewsListRequestTM {
      return this._model;
    }
    
    constructor(model: NewsListRequestTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('bezeichnung', new TypedFormControl<string>(model.bezeichnung, null));
      
      this.addControl('page', new TypedFormControl<number>(model.page, Validators.compose([OnValidators.required])));
      
      this.addControl('pageSize', new TypedFormControl<number>(model.pageSize, Validators.compose([OnValidators.required])));
      
      this.addControl('sort', new TypedFormControl<string>(model.sort, null));
      
      this.addControl('sortOrder', new TypedFormControl<SortOrder>(model.sortOrder, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get page() {
      return this.controls.page as any as TypedFormControl<number>;
    }
    
    get pageSize() {
      return this.controls.pageSize as any as TypedFormControl<number>;
    }
    
    get sort() {
      return this.controls.sort as any as TypedFormControl<string>;
    }
    
    get sortOrder() {
      return this.controls.sortOrder as any as TypedFormControl<SortOrder>;
    }
    
    get model(): NewsListRequestTM {
      return {
        bezeichnung: this.typed.controls.bezeichnung?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
    
    getRawValue(): NewsListRequestTM {
      return {
        bezeichnung: this.typed.controls.bezeichnung?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<NewsListRequestTM.Value, NewsListRequestTM.FormGroup> {
    
    constructor(model?: NewsListRequestTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new NewsListRequestTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as NewsListRequestTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<NewsListRequestTM.Value, NewsListRequestTM.FormGroup> {
    
    constructor(model?: { [key: string]: NewsListRequestTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new NewsListRequestTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface NewsListItemTM {
  id: number;
  bezeichnung: TMValue<string>;
  aktiv: TMValue<boolean>;
  kategorie: TMValue<NewsKategorie>;
  markAsDeleted: boolean;
}

export namespace NewsListItemTM {

  export function empty(): NewsListItemTM {
    return {
      id: 0,
      bezeichnung: { right: PropertyRights.All, value: null },
      aktiv: { right: PropertyRights.All, value: null },
      kategorie: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    bezeichnung: string | null;
    aktiv: boolean;
    kategorie: NewsKategorie;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    bezeichnung: TypedFormControl<string>;
    aktiv: TypedFormControl<boolean>;
    kategorie: TypedFormControl<NewsKategorie>;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<NewsListItemTM.Value, Controls> {
    
    private _model: NewsListItemTM;
    
    public get originalModel(): NewsListItemTM {
      return this._model;
    }
    
    constructor(model: NewsListItemTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.bezeichnung != null) {
        const control = new TypedFormControl<string>(model.bezeichnung.value, Validators.compose([OnValidators.required]));
        control.right = model.bezeichnung.right;
        this.addControl('bezeichnung', control);
      }
      
      if(model.aktiv != null) {
        const control = new TypedFormControl<boolean>(model.aktiv.value, null);
        control.right = model.aktiv.right;
        this.addControl('aktiv', control);
      }
      
      if(model.kategorie != null) {
        const control = new TypedFormControl<NewsKategorie>(model.kategorie.value, Validators.compose([OnValidators.required]));
        control.right = model.kategorie.right;
        this.addControl('kategorie', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get aktiv() {
      return this.controls.aktiv as any as TypedFormControl<boolean>;
    }
    
    get kategorie() {
      return this.controls.kategorie as any as TypedFormControl<NewsKategorie>;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    get model(): NewsListItemTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        aktiv: this.typed.controls.aktiv != undefined ? {
          value: this.typed.controls.aktiv.model,
          right: this.typed.controls.aktiv.right,
        } : undefined,
        kategorie: this.typed.controls.kategorie != undefined ? {
          value: this.typed.controls.kategorie.model,
          right: this.typed.controls.kategorie.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): NewsListItemTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        aktiv: this.typed.controls.aktiv != undefined ? {
          value: this.typed.controls.aktiv.model,
          right: this.typed.controls.aktiv.right,
        } : undefined,
        kategorie: this.typed.controls.kategorie != undefined ? {
          value: this.typed.controls.kategorie.model,
          right: this.typed.controls.kategorie.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<NewsListItemTM.Value, NewsListItemTM.FormGroup> {
    
    constructor(model?: NewsListItemTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new NewsListItemTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as NewsListItemTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<NewsListItemTM.Value, NewsListItemTM.FormGroup> {
    
    constructor(model?: { [key: string]: NewsListItemTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new NewsListItemTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface LizenzvertragTM {
  bezeichnung: TMValue<string>;
  verfahrensname: TMValue<string>;
  lizenznummer: TMValue<string>;
  laufzeitBis: TMValue<string>;
  status: TMValue<LizenzStatus>;
  externalLizenzId: TMValue<number>;
  maxBestellungenProMonat: TMValue<number>;
  maxLieferanten: TMValue<number>;
  lizenzvertragUnterschrieben: TMValue<Base64File>;
  lizenzvertragUnterschriebenAdiSmartId: TMValue<number>;
  verteilerRechnungen: TMValue<string>;
  version: TMValue<number>;
}

export namespace LizenzvertragTM {

  export function empty(): LizenzvertragTM {
    return {
      bezeichnung: { right: PropertyRights.All, value: null },
      verfahrensname: { right: PropertyRights.All, value: null },
      lizenznummer: { right: PropertyRights.All, value: null },
      laufzeitBis: { right: PropertyRights.All, value: null },
      status: { right: PropertyRights.All, value: null },
      externalLizenzId: { right: PropertyRights.All, value: null },
      maxBestellungenProMonat: { right: PropertyRights.All, value: null },
      maxLieferanten: { right: PropertyRights.All, value: null },
      lizenzvertragUnterschrieben: { right: PropertyRights.All, value: null },
      lizenzvertragUnterschriebenAdiSmartId: { right: PropertyRights.All, value: null },
      verteilerRechnungen: { right: PropertyRights.All, value: null },
      version: { right: PropertyRights.All, value: null },
    };
  }
  
  export interface Value {
    bezeichnung: string | null;
    verfahrensname: string | null;
    lizenznummer: string | null;
    laufzeitBis: string | null;
    status: LizenzStatus;
    externalLizenzId: number;
    maxBestellungenProMonat: number;
    maxLieferanten: number;
    lizenzvertragUnterschrieben: Base64File | null;
    lizenzvertragUnterschriebenAdiSmartId: number;
    verteilerRechnungen: string | null;
    version: number;
  }
  
  interface Controls {
    bezeichnung: TypedFormControl<string>;
    verfahrensname: TypedFormControl<string>;
    lizenznummer: TypedFormControl<string>;
    laufzeitBis: TypedFormControl<string>;
    status: TypedFormControl<LizenzStatus>;
    externalLizenzId: TypedFormControl<number>;
    maxBestellungenProMonat: TypedFormControl<number>;
    maxLieferanten: TypedFormControl<number>;
    lizenzvertragUnterschrieben: TypedFormControl<Base64File>;
    lizenzvertragUnterschriebenAdiSmartId: TypedFormControl<number>;
    verteilerRechnungen: TypedFormControl<string>;
    version: TypedFormControl<number>;
  }
  
  export class FormGroup extends TypedFormGroup<LizenzvertragTM.Value, Controls> {
    
    private _model: LizenzvertragTM;
    
    public get originalModel(): LizenzvertragTM {
      return this._model;
    }
    
    constructor(model: LizenzvertragTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      if(model.bezeichnung != null) {
        const control = new TypedFormControl<string>(model.bezeichnung.value, null);
        control.right = model.bezeichnung.right;
        this.addControl('bezeichnung', control);
      }
      
      if(model.verfahrensname != null) {
        const control = new TypedFormControl<string>(model.verfahrensname.value, null);
        control.right = model.verfahrensname.right;
        this.addControl('verfahrensname', control);
      }
      
      if(model.lizenznummer != null) {
        const control = new TypedFormControl<string>(model.lizenznummer.value, null);
        control.right = model.lizenznummer.right;
        this.addControl('lizenznummer', control);
      }
      
      if(model.laufzeitBis != null) {
        const control = new TypedFormControl<string>(model.laufzeitBis.value, null);
        control.right = model.laufzeitBis.right;
        this.addControl('laufzeitBis', control);
      }
      
      if(model.status != null) {
        const control = new TypedFormControl<LizenzStatus>(model.status.value, null);
        control.right = model.status.right;
        this.addControl('status', control);
      }
      
      if(model.externalLizenzId != null) {
        const control = new TypedFormControl<number>(model.externalLizenzId.value, null);
        control.right = model.externalLizenzId.right;
        this.addControl('externalLizenzId', control);
      }
      
      if(model.maxBestellungenProMonat != null) {
        const control = new TypedFormControl<number>(model.maxBestellungenProMonat.value, null);
        control.right = model.maxBestellungenProMonat.right;
        this.addControl('maxBestellungenProMonat', control);
      }
      
      if(model.maxLieferanten != null) {
        const control = new TypedFormControl<number>(model.maxLieferanten.value, null);
        control.right = model.maxLieferanten.right;
        this.addControl('maxLieferanten', control);
      }
      
      if(model.lizenzvertragUnterschrieben != null) {
        const control = new TypedFormControl<Base64File>(model.lizenzvertragUnterschrieben.value, null);
        control.right = model.lizenzvertragUnterschrieben.right;
        this.addControl('lizenzvertragUnterschrieben', control);
      }
      
      if(model.lizenzvertragUnterschriebenAdiSmartId != null) {
        const control = new TypedFormControl<number>(model.lizenzvertragUnterschriebenAdiSmartId.value, null);
        control.right = model.lizenzvertragUnterschriebenAdiSmartId.right;
        this.addControl('lizenzvertragUnterschriebenAdiSmartId', control);
      }
      
      if(model.verteilerRechnungen != null) {
        const control = new TypedFormControl<string>(model.verteilerRechnungen.value, null);
        control.right = model.verteilerRechnungen.right;
        this.addControl('verteilerRechnungen', control);
      }
      
      if(model.version != null) {
        const control = new TypedFormControl<number>(model.version.value, null);
        control.right = model.version.right;
        this.addControl('version', control);
      }
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get verfahrensname() {
      return this.controls.verfahrensname as any as TypedFormControl<string>;
    }
    
    get lizenznummer() {
      return this.controls.lizenznummer as any as TypedFormControl<string>;
    }
    
    get laufzeitBis() {
      return this.controls.laufzeitBis as any as TypedFormControl<string>;
    }
    
    get $status() {
      return this.controls.status as any as TypedFormControl<LizenzStatus>;
    }
    
    get externalLizenzId() {
      return this.controls.externalLizenzId as any as TypedFormControl<number>;
    }
    
    get maxBestellungenProMonat() {
      return this.controls.maxBestellungenProMonat as any as TypedFormControl<number>;
    }
    
    get maxLieferanten() {
      return this.controls.maxLieferanten as any as TypedFormControl<number>;
    }
    
    get lizenzvertragUnterschrieben() {
      return this.controls.lizenzvertragUnterschrieben as any as TypedFormControl<Base64File>;
    }
    
    get lizenzvertragUnterschriebenAdiSmartId() {
      return this.controls.lizenzvertragUnterschriebenAdiSmartId as any as TypedFormControl<number>;
    }
    
    get verteilerRechnungen() {
      return this.controls.verteilerRechnungen as any as TypedFormControl<string>;
    }
    
    get version() {
      return this.controls.version as any as TypedFormControl<number>;
    }
    
    get model(): LizenzvertragTM {
      return {
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        verfahrensname: this.typed.controls.verfahrensname != undefined ? {
          value: this.typed.controls.verfahrensname.model,
          right: this.typed.controls.verfahrensname.right,
        } : undefined,
        lizenznummer: this.typed.controls.lizenznummer != undefined ? {
          value: this.typed.controls.lizenznummer.model,
          right: this.typed.controls.lizenznummer.right,
        } : undefined,
        laufzeitBis: this.typed.controls.laufzeitBis != undefined ? {
          value: this.typed.controls.laufzeitBis.model,
          right: this.typed.controls.laufzeitBis.right,
        } : undefined,
        status: this.typed.controls.status != undefined ? {
          value: this.typed.controls.status.model,
          right: this.typed.controls.status.right,
        } : undefined,
        externalLizenzId: this.typed.controls.externalLizenzId != undefined ? {
          value: this.typed.controls.externalLizenzId.model,
          right: this.typed.controls.externalLizenzId.right,
        } : undefined,
        maxBestellungenProMonat: this.typed.controls.maxBestellungenProMonat != undefined ? {
          value: this.typed.controls.maxBestellungenProMonat.model,
          right: this.typed.controls.maxBestellungenProMonat.right,
        } : undefined,
        maxLieferanten: this.typed.controls.maxLieferanten != undefined ? {
          value: this.typed.controls.maxLieferanten.model,
          right: this.typed.controls.maxLieferanten.right,
        } : undefined,
        lizenzvertragUnterschrieben: this.typed.controls.lizenzvertragUnterschrieben != undefined ? {
          value: this.typed.controls.lizenzvertragUnterschrieben.model,
          right: this.typed.controls.lizenzvertragUnterschrieben.right,
        } : undefined,
        lizenzvertragUnterschriebenAdiSmartId: this.typed.controls.lizenzvertragUnterschriebenAdiSmartId != undefined ? {
          value: this.typed.controls.lizenzvertragUnterschriebenAdiSmartId.model,
          right: this.typed.controls.lizenzvertragUnterschriebenAdiSmartId.right,
        } : undefined,
        verteilerRechnungen: this.typed.controls.verteilerRechnungen != undefined ? {
          value: this.typed.controls.verteilerRechnungen.model,
          right: this.typed.controls.verteilerRechnungen.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
      };
    }
    
    getRawValue(): LizenzvertragTM {
      return {
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        verfahrensname: this.typed.controls.verfahrensname != undefined ? {
          value: this.typed.controls.verfahrensname.model,
          right: this.typed.controls.verfahrensname.right,
        } : undefined,
        lizenznummer: this.typed.controls.lizenznummer != undefined ? {
          value: this.typed.controls.lizenznummer.model,
          right: this.typed.controls.lizenznummer.right,
        } : undefined,
        laufzeitBis: this.typed.controls.laufzeitBis != undefined ? {
          value: this.typed.controls.laufzeitBis.model,
          right: this.typed.controls.laufzeitBis.right,
        } : undefined,
        status: this.typed.controls.status != undefined ? {
          value: this.typed.controls.status.model,
          right: this.typed.controls.status.right,
        } : undefined,
        externalLizenzId: this.typed.controls.externalLizenzId != undefined ? {
          value: this.typed.controls.externalLizenzId.model,
          right: this.typed.controls.externalLizenzId.right,
        } : undefined,
        maxBestellungenProMonat: this.typed.controls.maxBestellungenProMonat != undefined ? {
          value: this.typed.controls.maxBestellungenProMonat.model,
          right: this.typed.controls.maxBestellungenProMonat.right,
        } : undefined,
        maxLieferanten: this.typed.controls.maxLieferanten != undefined ? {
          value: this.typed.controls.maxLieferanten.model,
          right: this.typed.controls.maxLieferanten.right,
        } : undefined,
        lizenzvertragUnterschrieben: this.typed.controls.lizenzvertragUnterschrieben != undefined ? {
          value: this.typed.controls.lizenzvertragUnterschrieben.model,
          right: this.typed.controls.lizenzvertragUnterschrieben.right,
        } : undefined,
        lizenzvertragUnterschriebenAdiSmartId: this.typed.controls.lizenzvertragUnterschriebenAdiSmartId != undefined ? {
          value: this.typed.controls.lizenzvertragUnterschriebenAdiSmartId.model,
          right: this.typed.controls.lizenzvertragUnterschriebenAdiSmartId.right,
        } : undefined,
        verteilerRechnungen: this.typed.controls.verteilerRechnungen != undefined ? {
          value: this.typed.controls.verteilerRechnungen.model,
          right: this.typed.controls.verteilerRechnungen.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<LizenzvertragTM.Value, LizenzvertragTM.FormGroup> {
    
    constructor(model?: LizenzvertragTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new LizenzvertragTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as LizenzvertragTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<LizenzvertragTM.Value, LizenzvertragTM.FormGroup> {
    
    constructor(model?: { [key: string]: LizenzvertragTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new LizenzvertragTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface Base64File {
  filename?: string | null;
  contentType?: string | null;
  data?: string | null;
}

export interface LizenzvertragListRequestTM {
  kundeId: number;
  verfahrensname?: string | null;
  lizenznummer?: string | null;
  status: LizenzStatus;
  page: number;
  pageSize: number;
  sort?: string | null;
  sortOrder: SortOrder;
}

export namespace LizenzvertragListRequestTM {

  export function empty(): LizenzvertragListRequestTM {
    return {
      kundeId: 0,
      verfahrensname: null,
      lizenznummer: null,
      status: 0,
      page: 0,
      pageSize: 0,
      sort: null,
      sortOrder: -1,
    };
  }
  
  export interface Value {
    kundeId: number;
    verfahrensname: string | null;
    lizenznummer: string | null;
    status: LizenzStatus;
    page: number;
    pageSize: number;
    sort: string | null;
    sortOrder: SortOrder;
  }
  
  interface Controls {
    kundeId: TypedFormControl<number>;
    verfahrensname: TypedFormControl<string>;
    lizenznummer: TypedFormControl<string>;
    status: TypedFormControl<LizenzStatus>;
    page: TypedFormControl<number>;
    pageSize: TypedFormControl<number>;
    sort: TypedFormControl<string>;
    sortOrder: TypedFormControl<SortOrder>;
  }
  
  export class FormGroup extends TypedFormGroup<LizenzvertragListRequestTM.Value, Controls> {
    
    private _model: LizenzvertragListRequestTM;
    
    public get originalModel(): LizenzvertragListRequestTM {
      return this._model;
    }
    
    constructor(model: LizenzvertragListRequestTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('kundeId', new TypedFormControl<number>(model.kundeId, null));
      
      this.addControl('verfahrensname', new TypedFormControl<string>(model.verfahrensname, null));
      
      this.addControl('lizenznummer', new TypedFormControl<string>(model.lizenznummer, null));
      
      this.addControl('status', new TypedFormControl<LizenzStatus>(model.status, null));
      
      this.addControl('page', new TypedFormControl<number>(model.page, Validators.compose([OnValidators.required])));
      
      this.addControl('pageSize', new TypedFormControl<number>(model.pageSize, Validators.compose([OnValidators.required])));
      
      this.addControl('sort', new TypedFormControl<string>(model.sort, null));
      
      this.addControl('sortOrder', new TypedFormControl<SortOrder>(model.sortOrder, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get kundeId() {
      return this.controls.kundeId as any as TypedFormControl<number>;
    }
    
    get verfahrensname() {
      return this.controls.verfahrensname as any as TypedFormControl<string>;
    }
    
    get lizenznummer() {
      return this.controls.lizenznummer as any as TypedFormControl<string>;
    }
    
    get $status() {
      return this.controls.status as any as TypedFormControl<LizenzStatus>;
    }
    
    get page() {
      return this.controls.page as any as TypedFormControl<number>;
    }
    
    get pageSize() {
      return this.controls.pageSize as any as TypedFormControl<number>;
    }
    
    get sort() {
      return this.controls.sort as any as TypedFormControl<string>;
    }
    
    get sortOrder() {
      return this.controls.sortOrder as any as TypedFormControl<SortOrder>;
    }
    
    get model(): LizenzvertragListRequestTM {
      return {
        kundeId: this.typed.controls.kundeId?.model,
        verfahrensname: this.typed.controls.verfahrensname?.model,
        lizenznummer: this.typed.controls.lizenznummer?.model,
        status: this.typed.controls.status?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
    
    getRawValue(): LizenzvertragListRequestTM {
      return {
        kundeId: this.typed.controls.kundeId?.model,
        verfahrensname: this.typed.controls.verfahrensname?.model,
        lizenznummer: this.typed.controls.lizenznummer?.model,
        status: this.typed.controls.status?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<LizenzvertragListRequestTM.Value, LizenzvertragListRequestTM.FormGroup> {
    
    constructor(model?: LizenzvertragListRequestTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new LizenzvertragListRequestTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as LizenzvertragListRequestTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<LizenzvertragListRequestTM.Value, LizenzvertragListRequestTM.FormGroup> {
    
    constructor(model?: { [key: string]: LizenzvertragListRequestTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new LizenzvertragListRequestTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface LizenzvertragListTM {
  bezeichnung?: string | null;
  verfahrensname?: string | null;
  status: LizenzStatus;
  lizenznummer?: string | null;
  externalLizenzId: number;
}

export interface Option {
  value: any;
  label?: string | null;
}

export interface SupportListRequestTM {
  betreff?: string | null;
  status?: SupportStatus | null;
  kundenIds?: number[] | null;
  page: number;
  pageSize: number;
  sort?: string | null;
  sortOrder: SortOrder;
}

export namespace SupportListRequestTM {

  export function empty(): SupportListRequestTM {
    return {
      betreff: null,
      status: null,
      kundenIds: [],
      page: 0,
      pageSize: 0,
      sort: null,
      sortOrder: -1,
    };
  }
  
  export interface Value {
    betreff: string | null;
    status: SupportStatus | null;
    kundenIds: number[] | null;
    page: number;
    pageSize: number;
    sort: string | null;
    sortOrder: SortOrder;
  }
  
  interface Controls {
    betreff: TypedFormControl<string>;
    status: TypedFormControl<SupportStatus>;
    kundenIds: TypedFormControl<number[]>;
    page: TypedFormControl<number>;
    pageSize: TypedFormControl<number>;
    sort: TypedFormControl<string>;
    sortOrder: TypedFormControl<SortOrder>;
  }
  
  export class FormGroup extends TypedFormGroup<SupportListRequestTM.Value, Controls> {
    
    private _model: SupportListRequestTM;
    
    public get originalModel(): SupportListRequestTM {
      return this._model;
    }
    
    constructor(model: SupportListRequestTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('betreff', new TypedFormControl<string>(model.betreff, null));
      
      this.addControl('status', new TypedFormControl<SupportStatus>(model.status, null));
      
      this.addControl('kundenIds', new TypedFormControl<number[]>(model.kundenIds || [], null));
      
      this.addControl('page', new TypedFormControl<number>(model.page, Validators.compose([OnValidators.required])));
      
      this.addControl('pageSize', new TypedFormControl<number>(model.pageSize, Validators.compose([OnValidators.required])));
      
      this.addControl('sort', new TypedFormControl<string>(model.sort, null));
      
      this.addControl('sortOrder', new TypedFormControl<SortOrder>(model.sortOrder, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get betreff() {
      return this.controls.betreff as any as TypedFormControl<string>;
    }
    
    get $status() {
      return this.controls.status as any as TypedFormControl<SupportStatus>;
    }
    
    get kundenIds() {
      return this.controls.kundenIds as any as TypedFormControl<number[]>;
    }
    
    get page() {
      return this.controls.page as any as TypedFormControl<number>;
    }
    
    get pageSize() {
      return this.controls.pageSize as any as TypedFormControl<number>;
    }
    
    get sort() {
      return this.controls.sort as any as TypedFormControl<string>;
    }
    
    get sortOrder() {
      return this.controls.sortOrder as any as TypedFormControl<SortOrder>;
    }
    
    get model(): SupportListRequestTM {
      return {
        betreff: this.typed.controls.betreff?.model,
        status: this.typed.controls.status?.model,
        kundenIds: this.typed.controls.kundenIds?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
    
    getRawValue(): SupportListRequestTM {
      return {
        betreff: this.typed.controls.betreff?.model,
        status: this.typed.controls.status?.model,
        kundenIds: this.typed.controls.kundenIds?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<SupportListRequestTM.Value, SupportListRequestTM.FormGroup> {
    
    constructor(model?: SupportListRequestTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new SupportListRequestTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as SupportListRequestTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<SupportListRequestTM.Value, SupportListRequestTM.FormGroup> {
    
    constructor(model?: { [key: string]: SupportListRequestTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new SupportListRequestTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface TicketTm {
  ticketStatus: SupportStatus;
  link?: string | null;
  id: number;
  title?: string | null;
  description?: string | null;
  createdAt?: string | null;
  changedAt?: string | null;
  kunde?: string | null;
}

export interface EMailDataContainerTM {
  id: number;
  betreff?: string | null;
  empfaenger?: string | null;
  kundeId: number;
  supportId: number;
  nachricht?: string | null;
  eMailTyp: EMailTyp;
}

export interface EMailUpdateTM {
  id: number;
  absender: TMValue<string>;
  absendername: TMValue<string>;
  betreff: TMValue<string>;
  bounceStatus: TMValue<BounceStatus>;
  cc: TMValue<string>;
  datumUTC: TMValue<string>;
  empfaenger: TMValue<string>;
  empfaengername: TMValue<string>;
  nachricht: TMValue<string>;
  isPosteingang: TMValue<boolean>;
  isSpam: TMValue<boolean>;
  verzeichnis: TMValue<MailVerzeichnis>;
  supportId: TMValue<number>;
  kundeId: TMValue<number>;
  createdAtUTC: TMValue<string>;
  version: TMValue<number>;
  dateiColl: TMValue<DateiUpdateTM[]>;
  markAsDeleted: boolean;
}

export namespace EMailUpdateTM {

  export function empty(): EMailUpdateTM {
    return {
      id: 0,
      absender: { right: PropertyRights.All, value: null },
      absendername: { right: PropertyRights.All, value: null },
      betreff: { right: PropertyRights.All, value: null },
      bounceStatus: { right: PropertyRights.All, value: null },
      cc: { right: PropertyRights.All, value: null },
      datumUTC: { right: PropertyRights.All, value: null },
      empfaenger: { right: PropertyRights.All, value: null },
      empfaengername: { right: PropertyRights.All, value: null },
      nachricht: { right: PropertyRights.All, value: null },
      isPosteingang: { right: PropertyRights.All, value: null },
      isSpam: { right: PropertyRights.All, value: null },
      verzeichnis: { right: PropertyRights.All, value: null },
      supportId: { right: PropertyRights.All, value: null },
      kundeId: { right: PropertyRights.All, value: null },
      createdAtUTC: { right: PropertyRights.All, value: null },
      version: { right: PropertyRights.All, value: null },
      dateiColl: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    absender: string | null;
    absendername: string | null;
    betreff: string | null;
    bounceStatus: BounceStatus;
    cc: string | null;
    datumUTC: string | null;
    empfaenger: string | null;
    empfaengername: string | null;
    nachricht: string | null;
    isPosteingang: boolean;
    isSpam: boolean;
    verzeichnis: MailVerzeichnis;
    supportId: number | null;
    kundeId: number | null;
    createdAtUTC: string | null;
    version: number | null;
    dateiColl: DateiUpdateTM.Value[] | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    absender: TypedFormControl<string>;
    absendername: TypedFormControl<string>;
    betreff: TypedFormControl<string>;
    bounceStatus: TypedFormControl<BounceStatus>;
    cc: TypedFormControl<string>;
    datumUTC: TypedFormControl<string>;
    empfaenger: TypedFormControl<string>;
    empfaengername: TypedFormControl<string>;
    nachricht: TypedFormControl<string>;
    isPosteingang: TypedFormControl<boolean>;
    isSpam: TypedFormControl<boolean>;
    verzeichnis: TypedFormControl<MailVerzeichnis>;
    supportId: TypedFormControl<number>;
    kundeId: TypedFormControl<number>;
    createdAtUTC: TypedFormControl<string>;
    version: TypedFormControl<number>;
    dateiColl: DateiUpdateTM.FormArray;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<EMailUpdateTM.Value, Controls> {
    
    private _model: EMailUpdateTM;
    
    public get originalModel(): EMailUpdateTM {
      return this._model;
    }
    
    constructor(model: EMailUpdateTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.absender != null) {
        const control = new TypedFormControl<string>(model.absender.value, Validators.compose([OnValidators.required]));
        control.right = model.absender.right;
        this.addControl('absender', control);
      }
      
      if(model.absendername != null) {
        const control = new TypedFormControl<string>(model.absendername.value, null);
        control.right = model.absendername.right;
        this.addControl('absendername', control);
      }
      
      if(model.betreff != null) {
        const control = new TypedFormControl<string>(model.betreff.value, Validators.compose([OnValidators.required]), Validators.composeAsync([new SanitizeValidator(['h1','h2','h3','h4','h5','h6','p','span','b','i','div','img','a','ul','li','ol','strong','em','br'],null,['alt','width','height','href','style','class','target','rel'],['float','align','text-align','margin','margin-top','margin-right','margin-bottom','margin-left','padding','padding-top','padding-right','padding-bottom','padding-left'],null, false).validate]));
        control.right = model.betreff.right;
        this.addControl('betreff', control);
      }
      
      if(model.bounceStatus != null) {
        const control = new TypedFormControl<BounceStatus>(model.bounceStatus.value, Validators.compose([OnValidators.required]));
        control.right = model.bounceStatus.right;
        this.addControl('bounceStatus', control);
      }
      
      if(model.cc != null) {
        const control = new TypedFormControl<string>(model.cc.value, null);
        control.right = model.cc.right;
        this.addControl('cc', control);
      }
      
      if(model.datumUTC != null) {
        const control = new TypedFormControl<string>(model.datumUTC.value, null);
        control.right = model.datumUTC.right;
        this.addControl('datumUTC', control);
      }
      
      if(model.empfaenger != null) {
        const control = new TypedFormControl<string>(model.empfaenger.value, Validators.compose([OnValidators.required]));
        control.right = model.empfaenger.right;
        this.addControl('empfaenger', control);
      }
      
      if(model.empfaengername != null) {
        const control = new TypedFormControl<string>(model.empfaengername.value, null);
        control.right = model.empfaengername.right;
        this.addControl('empfaengername', control);
      }
      
      if(model.nachricht != null) {
        const control = new TypedFormControl<string>(model.nachricht.value, null, Validators.composeAsync([new SanitizeValidator(['h1','h2','h3','h4','h5','h6','p','span','b','i','div','img','a','ul','li','ol','strong','em','br','table','thead','tbody','tfoot','th','td'],null,['alt','width','height','href','style','class','target','rel'],['float','align','text-align','margin','margin-top','margin-right','margin-bottom','margin-left','padding','padding-top','padding-right','padding-bottom','padding-left'],null, false).validate]));
        control.right = model.nachricht.right;
        this.addControl('nachricht', control);
      }
      
      if(model.isPosteingang != null) {
        const control = new TypedFormControl<boolean>(model.isPosteingang.value, null);
        control.right = model.isPosteingang.right;
        this.addControl('isPosteingang', control);
      }
      
      if(model.isSpam != null) {
        const control = new TypedFormControl<boolean>(model.isSpam.value, null);
        control.right = model.isSpam.right;
        this.addControl('isSpam', control);
      }
      
      if(model.verzeichnis != null) {
        const control = new TypedFormControl<MailVerzeichnis>(model.verzeichnis.value, Validators.compose([OnValidators.required]));
        control.right = model.verzeichnis.right;
        this.addControl('verzeichnis', control);
      }
      
      if(model.supportId != null) {
        const control = new TypedFormControl<number>(model.supportId.value, null);
        control.right = model.supportId.right;
        this.addControl('supportId', control);
      }
      
      if(model.kundeId != null) {
        const control = new TypedFormControl<number>(model.kundeId.value, null);
        control.right = model.kundeId.right;
        this.addControl('kundeId', control);
      }
      
      if(model.createdAtUTC != null) {
        const control = new TypedFormControl<string>(model.createdAtUTC.value, Validators.compose([OnValidators.required]));
        control.right = model.createdAtUTC.right;
        this.addControl('createdAtUTC', control);
      }
      
      if(model.version != null) {
        const control = new TypedFormControl<number>(model.version.value, null);
        control.right = model.version.right;
        this.addControl('version', control);
      }
      
      if(model.dateiColl != null) {
        const control = new DateiUpdateTM.FormArray(model.dateiColl.value || [], null);
        control.right = model.dateiColl.right;
        this.addControl('dateiColl', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get absender() {
      return this.controls.absender as any as TypedFormControl<string>;
    }
    
    get absendername() {
      return this.controls.absendername as any as TypedFormControl<string>;
    }
    
    get betreff() {
      return this.controls.betreff as any as TypedFormControl<string>;
    }
    
    get bounceStatus() {
      return this.controls.bounceStatus as any as TypedFormControl<BounceStatus>;
    }
    
    get cc() {
      return this.controls.cc as any as TypedFormControl<string>;
    }
    
    get datumUTC() {
      return this.controls.datumUTC as any as TypedFormControl<string>;
    }
    
    get empfaenger() {
      return this.controls.empfaenger as any as TypedFormControl<string>;
    }
    
    get empfaengername() {
      return this.controls.empfaengername as any as TypedFormControl<string>;
    }
    
    get nachricht() {
      return this.controls.nachricht as any as TypedFormControl<string>;
    }
    
    get isPosteingang() {
      return this.controls.isPosteingang as any as TypedFormControl<boolean>;
    }
    
    get isSpam() {
      return this.controls.isSpam as any as TypedFormControl<boolean>;
    }
    
    get verzeichnis() {
      return this.controls.verzeichnis as any as TypedFormControl<MailVerzeichnis>;
    }
    
    get supportId() {
      return this.controls.supportId as any as TypedFormControl<number>;
    }
    
    get kundeId() {
      return this.controls.kundeId as any as TypedFormControl<number>;
    }
    
    get createdAtUTC() {
      return this.controls.createdAtUTC as any as TypedFormControl<string>;
    }
    
    get version() {
      return this.controls.version as any as TypedFormControl<number>;
    }
    
    get dateiColl() {
      return this.controls.dateiColl as any as DateiUpdateTM.FormArray;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    dateiCollAdd(value?: DateiUpdateTM, dontMarkAsDirty?: boolean): DateiUpdateTM.FormGroup  {
      if (this.disabled) return null;
      let control = new DateiUpdateTM.FormGroup(value ? value : DateiUpdateTM.empty());
      this.dateiColl.push(control);
      if (!dontMarkAsDirty) {
        this.dateiColl.markAsDirty();
      }
      return control;
    }
    
    dateiCollRemove(control: DateiUpdateTM.FormGroup, dontMarkAsDirty?: boolean): void {
      if (this.disabled) return;
      let array = this.dateiColl.typed.controls;
      let index = array.indexOf(control);
      let removed = this.dateiColl.typed.at(index);
      if (removed.model.id != 0) {
        removed.markAsDeleted.setValue(true);
      } else {
        this.dateiColl.removeAt(index);
      }
      if (!dontMarkAsDirty) {
        this.dateiColl.markAsDirty();
      }
    }
    
    dateiCollMarkAsDeleted(control: DateiUpdateTM.FormGroup, dontMarkAsDirty?: boolean): void {
      control.markAsDeleted.setValue(true);
      if (!dontMarkAsDirty) {
        this.dateiColl.markAsDirty();
      }
    }
    
    get model(): EMailUpdateTM {
      return {
        id: this.typed.controls.id?.model,
        absender: this.typed.controls.absender != undefined ? {
          value: this.typed.controls.absender.model,
          right: this.typed.controls.absender.right,
        } : undefined,
        absendername: this.typed.controls.absendername != undefined ? {
          value: this.typed.controls.absendername.model,
          right: this.typed.controls.absendername.right,
        } : undefined,
        betreff: this.typed.controls.betreff != undefined ? {
          value: this.typed.controls.betreff.model,
          right: this.typed.controls.betreff.right,
        } : undefined,
        bounceStatus: this.typed.controls.bounceStatus != undefined ? {
          value: this.typed.controls.bounceStatus.model,
          right: this.typed.controls.bounceStatus.right,
        } : undefined,
        cc: this.typed.controls.cc != undefined ? {
          value: this.typed.controls.cc.model,
          right: this.typed.controls.cc.right,
        } : undefined,
        datumUTC: this.typed.controls.datumUTC != undefined ? {
          value: this.typed.controls.datumUTC.model,
          right: this.typed.controls.datumUTC.right,
        } : undefined,
        empfaenger: this.typed.controls.empfaenger != undefined ? {
          value: this.typed.controls.empfaenger.model,
          right: this.typed.controls.empfaenger.right,
        } : undefined,
        empfaengername: this.typed.controls.empfaengername != undefined ? {
          value: this.typed.controls.empfaengername.model,
          right: this.typed.controls.empfaengername.right,
        } : undefined,
        nachricht: this.typed.controls.nachricht != undefined ? {
          value: this.typed.controls.nachricht.model,
          right: this.typed.controls.nachricht.right,
        } : undefined,
        isPosteingang: this.typed.controls.isPosteingang != undefined ? {
          value: this.typed.controls.isPosteingang.model,
          right: this.typed.controls.isPosteingang.right,
        } : undefined,
        isSpam: this.typed.controls.isSpam != undefined ? {
          value: this.typed.controls.isSpam.model,
          right: this.typed.controls.isSpam.right,
        } : undefined,
        verzeichnis: this.typed.controls.verzeichnis != undefined ? {
          value: this.typed.controls.verzeichnis.model,
          right: this.typed.controls.verzeichnis.right,
        } : undefined,
        supportId: this.typed.controls.supportId != undefined ? {
          value: this.typed.controls.supportId.model,
          right: this.typed.controls.supportId.right,
        } : undefined,
        kundeId: this.typed.controls.kundeId != undefined ? {
          value: this.typed.controls.kundeId.model,
          right: this.typed.controls.kundeId.right,
        } : undefined,
        createdAtUTC: this.typed.controls.createdAtUTC != undefined ? {
          value: this.typed.controls.createdAtUTC.model,
          right: this.typed.controls.createdAtUTC.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        dateiColl: this.typed.controls.dateiColl != undefined ? {
          value: this.typed.controls.dateiColl.model,
          right: this.typed.controls.dateiColl.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): EMailUpdateTM {
      return {
        id: this.typed.controls.id?.model,
        absender: this.typed.controls.absender != undefined ? {
          value: this.typed.controls.absender.model,
          right: this.typed.controls.absender.right,
        } : undefined,
        absendername: this.typed.controls.absendername != undefined ? {
          value: this.typed.controls.absendername.model,
          right: this.typed.controls.absendername.right,
        } : undefined,
        betreff: this.typed.controls.betreff != undefined ? {
          value: this.typed.controls.betreff.model,
          right: this.typed.controls.betreff.right,
        } : undefined,
        bounceStatus: this.typed.controls.bounceStatus != undefined ? {
          value: this.typed.controls.bounceStatus.model,
          right: this.typed.controls.bounceStatus.right,
        } : undefined,
        cc: this.typed.controls.cc != undefined ? {
          value: this.typed.controls.cc.model,
          right: this.typed.controls.cc.right,
        } : undefined,
        datumUTC: this.typed.controls.datumUTC != undefined ? {
          value: this.typed.controls.datumUTC.model,
          right: this.typed.controls.datumUTC.right,
        } : undefined,
        empfaenger: this.typed.controls.empfaenger != undefined ? {
          value: this.typed.controls.empfaenger.model,
          right: this.typed.controls.empfaenger.right,
        } : undefined,
        empfaengername: this.typed.controls.empfaengername != undefined ? {
          value: this.typed.controls.empfaengername.model,
          right: this.typed.controls.empfaengername.right,
        } : undefined,
        nachricht: this.typed.controls.nachricht != undefined ? {
          value: this.typed.controls.nachricht.model,
          right: this.typed.controls.nachricht.right,
        } : undefined,
        isPosteingang: this.typed.controls.isPosteingang != undefined ? {
          value: this.typed.controls.isPosteingang.model,
          right: this.typed.controls.isPosteingang.right,
        } : undefined,
        isSpam: this.typed.controls.isSpam != undefined ? {
          value: this.typed.controls.isSpam.model,
          right: this.typed.controls.isSpam.right,
        } : undefined,
        verzeichnis: this.typed.controls.verzeichnis != undefined ? {
          value: this.typed.controls.verzeichnis.model,
          right: this.typed.controls.verzeichnis.right,
        } : undefined,
        supportId: this.typed.controls.supportId != undefined ? {
          value: this.typed.controls.supportId.model,
          right: this.typed.controls.supportId.right,
        } : undefined,
        kundeId: this.typed.controls.kundeId != undefined ? {
          value: this.typed.controls.kundeId.model,
          right: this.typed.controls.kundeId.right,
        } : undefined,
        createdAtUTC: this.typed.controls.createdAtUTC != undefined ? {
          value: this.typed.controls.createdAtUTC.model,
          right: this.typed.controls.createdAtUTC.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        dateiColl: this.typed.controls.dateiColl != undefined ? {
          value: this.typed.controls.dateiColl.getRawValue(),
          right: this.typed.controls.dateiColl.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<EMailUpdateTM.Value, EMailUpdateTM.FormGroup> {
    
    constructor(model?: EMailUpdateTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new EMailUpdateTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as EMailUpdateTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<EMailUpdateTM.Value, EMailUpdateTM.FormGroup> {
    
    constructor(model?: { [key: string]: EMailUpdateTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new EMailUpdateTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface DateiUpdateTM {
  id: number;
  dateikategorieId: TMValue<number>;
  kundeId: TMValue<number>;
  emailId: TMValue<number>;
  fileData: TMValue<Base64File>;
  version: TMValue<number>;
  dateiKategorieRoll: TMValue<DateiKategorieForSelectTM>;
  markAsDeleted: boolean;
}

export namespace DateiUpdateTM {

  export function empty(): DateiUpdateTM {
    return {
      id: 0,
      dateikategorieId: { right: PropertyRights.All, value: null },
      kundeId: { right: PropertyRights.All, value: null },
      emailId: { right: PropertyRights.All, value: null },
      fileData: { right: PropertyRights.All, value: null },
      version: { right: PropertyRights.All, value: null },
      dateiKategorieRoll: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    dateikategorieId: number | null;
    kundeId: number | null;
    emailId: number | null;
    fileData: Base64File | null;
    version: number | null;
    dateiKategorieRoll: DateiKategorieForSelectTM.Value | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    dateikategorieId: TypedFormControl<number>;
    kundeId: TypedFormControl<number>;
    emailId: TypedFormControl<number>;
    fileData: TypedFormControl<Base64File>;
    version: TypedFormControl<number>;
    dateiKategorieRoll: DateiKategorieForSelectTM.FormGroup;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<DateiUpdateTM.Value, Controls> {
    
    private _model: DateiUpdateTM;
    
    public get originalModel(): DateiUpdateTM {
      return this._model;
    }
    
    constructor(model: DateiUpdateTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.dateikategorieId != null) {
        const control = new TypedFormControl<number>(model.dateikategorieId.value, null);
        control.right = model.dateikategorieId.right;
        this.addControl('dateikategorieId', control);
      }
      
      if(model.kundeId != null) {
        const control = new TypedFormControl<number>(model.kundeId.value, null);
        control.right = model.kundeId.right;
        this.addControl('kundeId', control);
      }
      
      if(model.emailId != null) {
        const control = new TypedFormControl<number>(model.emailId.value, null);
        control.right = model.emailId.right;
        this.addControl('emailId', control);
      }
      
      if(model.fileData != null) {
        const control = new TypedFormControl<Base64File>(model.fileData.value, null);
        control.right = model.fileData.right;
        this.addControl('fileData', control);
      }
      
      if(model.version != null) {
        const control = new TypedFormControl<number>(model.version.value, null);
        control.right = model.version.right;
        this.addControl('version', control);
      }
      
      if(model.dateiKategorieRoll != null && model.dateiKategorieRoll.value != null) {
        const control = new DateiKategorieForSelectTM.FormGroup(model.dateiKategorieRoll.value, null);
        control.right = model.dateiKategorieRoll.right;
        this.addControl('dateiKategorieRoll', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get dateikategorieId() {
      return this.controls.dateikategorieId as any as TypedFormControl<number>;
    }
    
    get kundeId() {
      return this.controls.kundeId as any as TypedFormControl<number>;
    }
    
    get emailId() {
      return this.controls.emailId as any as TypedFormControl<number>;
    }
    
    get fileData() {
      return this.controls.fileData as any as TypedFormControl<Base64File>;
    }
    
    get version() {
      return this.controls.version as any as TypedFormControl<number>;
    }
    
    get dateiKategorieRoll() {
      return this.controls.dateiKategorieRoll as any as DateiKategorieForSelectTM.FormGroup;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    get model(): DateiUpdateTM {
      return {
        id: this.typed.controls.id?.model,
        dateikategorieId: this.typed.controls.dateikategorieId != undefined ? {
          value: this.typed.controls.dateikategorieId.model,
          right: this.typed.controls.dateikategorieId.right,
        } : undefined,
        kundeId: this.typed.controls.kundeId != undefined ? {
          value: this.typed.controls.kundeId.model,
          right: this.typed.controls.kundeId.right,
        } : undefined,
        emailId: this.typed.controls.emailId != undefined ? {
          value: this.typed.controls.emailId.model,
          right: this.typed.controls.emailId.right,
        } : undefined,
        fileData: this.typed.controls.fileData != undefined ? {
          value: this.typed.controls.fileData.model,
          right: this.typed.controls.fileData.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        dateiKategorieRoll: this.typed.controls.dateiKategorieRoll != undefined ? {
          value: this.typed.controls.dateiKategorieRoll.model,
          right: this.typed.controls.dateiKategorieRoll.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): DateiUpdateTM {
      return {
        id: this.typed.controls.id?.model,
        dateikategorieId: this.typed.controls.dateikategorieId != undefined ? {
          value: this.typed.controls.dateikategorieId.model,
          right: this.typed.controls.dateikategorieId.right,
        } : undefined,
        kundeId: this.typed.controls.kundeId != undefined ? {
          value: this.typed.controls.kundeId.model,
          right: this.typed.controls.kundeId.right,
        } : undefined,
        emailId: this.typed.controls.emailId != undefined ? {
          value: this.typed.controls.emailId.model,
          right: this.typed.controls.emailId.right,
        } : undefined,
        fileData: this.typed.controls.fileData != undefined ? {
          value: this.typed.controls.fileData.model,
          right: this.typed.controls.fileData.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        dateiKategorieRoll: this.typed.controls.dateiKategorieRoll != undefined ? {
          value: this.typed.controls.dateiKategorieRoll.getRawValue(),
          right: this.typed.controls.dateiKategorieRoll.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<DateiUpdateTM.Value, DateiUpdateTM.FormGroup> {
    
    constructor(model?: DateiUpdateTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new DateiUpdateTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as DateiUpdateTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<DateiUpdateTM.Value, DateiUpdateTM.FormGroup> {
    
    constructor(model?: { [key: string]: DateiUpdateTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new DateiUpdateTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface DateiKategorieForSelectTM {
  id: number;
  bezeichnung: TMValue<string>;
  version: TMValue<number>;
  markAsDeleted: boolean;
}

export namespace DateiKategorieForSelectTM {

  export function empty(): DateiKategorieForSelectTM {
    return {
      id: 0,
      bezeichnung: { right: PropertyRights.All, value: null },
      version: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    bezeichnung: string | null;
    version: number | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    bezeichnung: TypedFormControl<string>;
    version: TypedFormControl<number>;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<DateiKategorieForSelectTM.Value, Controls> {
    
    private _model: DateiKategorieForSelectTM;
    
    public get originalModel(): DateiKategorieForSelectTM {
      return this._model;
    }
    
    constructor(model: DateiKategorieForSelectTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.bezeichnung != null) {
        const control = new TypedFormControl<string>(model.bezeichnung.value, Validators.compose([OnValidators.required]));
        control.right = model.bezeichnung.right;
        this.addControl('bezeichnung', control);
      }
      
      if(model.version != null) {
        const control = new TypedFormControl<number>(model.version.value, null);
        control.right = model.version.right;
        this.addControl('version', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get bezeichnung() {
      return this.controls.bezeichnung as any as TypedFormControl<string>;
    }
    
    get version() {
      return this.controls.version as any as TypedFormControl<number>;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    get model(): DateiKategorieForSelectTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): DateiKategorieForSelectTM {
      return {
        id: this.typed.controls.id?.model,
        bezeichnung: this.typed.controls.bezeichnung != undefined ? {
          value: this.typed.controls.bezeichnung.model,
          right: this.typed.controls.bezeichnung.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<DateiKategorieForSelectTM.Value, DateiKategorieForSelectTM.FormGroup> {
    
    constructor(model?: DateiKategorieForSelectTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new DateiKategorieForSelectTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as DateiKategorieForSelectTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<DateiKategorieForSelectTM.Value, DateiKategorieForSelectTM.FormGroup> {
    
    constructor(model?: { [key: string]: DateiKategorieForSelectTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new DateiKategorieForSelectTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface EMailCreateTM {
  id: number;
  absender: TMValue<string>;
  absendername: TMValue<string>;
  betreff: TMValue<string>;
  cc: TMValue<string>;
  datumUTC: TMValue<string>;
  empfaenger: TMValue<string>;
  empfaengername: TMValue<string>;
  nachricht: TMValue<string>;
  isPosteingang: TMValue<boolean>;
  isSpam: TMValue<boolean>;
  verzeichnis: TMValue<MailVerzeichnis>;
  supportId: TMValue<number>;
  version: TMValue<number>;
  dateiColl: TMValue<DateiUpdateTM[]>;
  markAsDeleted: boolean;
}

export namespace EMailCreateTM {

  export function empty(): EMailCreateTM {
    return {
      id: 0,
      absender: { right: PropertyRights.All, value: null },
      absendername: { right: PropertyRights.All, value: null },
      betreff: { right: PropertyRights.All, value: null },
      cc: { right: PropertyRights.All, value: null },
      datumUTC: { right: PropertyRights.All, value: null },
      empfaenger: { right: PropertyRights.All, value: null },
      empfaengername: { right: PropertyRights.All, value: null },
      nachricht: { right: PropertyRights.All, value: null },
      isPosteingang: { right: PropertyRights.All, value: null },
      isSpam: { right: PropertyRights.All, value: null },
      verzeichnis: { right: PropertyRights.All, value: null },
      supportId: { right: PropertyRights.All, value: null },
      version: { right: PropertyRights.All, value: null },
      dateiColl: { right: PropertyRights.All, value: null },
      markAsDeleted: false,
    };
  }
  
  export interface Value {
    id: number;
    absender: string | null;
    absendername: string | null;
    betreff: string | null;
    cc: string | null;
    datumUTC: string | null;
    empfaenger: string | null;
    empfaengername: string | null;
    nachricht: string | null;
    isPosteingang: boolean;
    isSpam: boolean;
    verzeichnis: MailVerzeichnis;
    supportId: number | null;
    version: number | null;
    dateiColl: DateiUpdateTM.Value[] | null;
    markAsDeleted: boolean;
  }
  
  interface Controls {
    id: TypedFormControl<number>;
    absender: TypedFormControl<string>;
    absendername: TypedFormControl<string>;
    betreff: TypedFormControl<string>;
    cc: TypedFormControl<string>;
    datumUTC: TypedFormControl<string>;
    empfaenger: TypedFormControl<string>;
    empfaengername: TypedFormControl<string>;
    nachricht: TypedFormControl<string>;
    isPosteingang: TypedFormControl<boolean>;
    isSpam: TypedFormControl<boolean>;
    verzeichnis: TypedFormControl<MailVerzeichnis>;
    supportId: TypedFormControl<number>;
    version: TypedFormControl<number>;
    dateiColl: DateiUpdateTM.FormArray;
    markAsDeleted: TypedFormControl<boolean>;
  }
  
  export class FormGroup extends TypedFormGroup<EMailCreateTM.Value, Controls> {
    
    private _model: EMailCreateTM;
    
    public get originalModel(): EMailCreateTM {
      return this._model;
    }
    
    constructor(model: EMailCreateTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('id', new TypedFormControl<number>(model.id, Validators.compose([OnValidators.required])));
      
      if(model.absender != null) {
        const control = new TypedFormControl<string>(model.absender.value, Validators.compose([OnValidators.required]));
        control.right = model.absender.right;
        this.addControl('absender', control);
      }
      
      if(model.absendername != null) {
        const control = new TypedFormControl<string>(model.absendername.value, null);
        control.right = model.absendername.right;
        this.addControl('absendername', control);
      }
      
      if(model.betreff != null) {
        const control = new TypedFormControl<string>(model.betreff.value, Validators.compose([OnValidators.required]));
        control.right = model.betreff.right;
        this.addControl('betreff', control);
      }
      
      if(model.cc != null) {
        const control = new TypedFormControl<string>(model.cc.value, null);
        control.right = model.cc.right;
        this.addControl('cc', control);
      }
      
      if(model.datumUTC != null) {
        const control = new TypedFormControl<string>(model.datumUTC.value, null);
        control.right = model.datumUTC.right;
        this.addControl('datumUTC', control);
      }
      
      if(model.empfaenger != null) {
        const control = new TypedFormControl<string>(model.empfaenger.value, Validators.compose([OnValidators.required]));
        control.right = model.empfaenger.right;
        this.addControl('empfaenger', control);
      }
      
      if(model.empfaengername != null) {
        const control = new TypedFormControl<string>(model.empfaengername.value, null);
        control.right = model.empfaengername.right;
        this.addControl('empfaengername', control);
      }
      
      if(model.nachricht != null) {
        const control = new TypedFormControl<string>(model.nachricht.value, null);
        control.right = model.nachricht.right;
        this.addControl('nachricht', control);
      }
      
      if(model.isPosteingang != null) {
        const control = new TypedFormControl<boolean>(model.isPosteingang.value, null);
        control.right = model.isPosteingang.right;
        this.addControl('isPosteingang', control);
      }
      
      if(model.isSpam != null) {
        const control = new TypedFormControl<boolean>(model.isSpam.value, null);
        control.right = model.isSpam.right;
        this.addControl('isSpam', control);
      }
      
      if(model.verzeichnis != null) {
        const control = new TypedFormControl<MailVerzeichnis>(model.verzeichnis.value, Validators.compose([OnValidators.required]));
        control.right = model.verzeichnis.right;
        this.addControl('verzeichnis', control);
      }
      
      if(model.supportId != null) {
        const control = new TypedFormControl<number>(model.supportId.value, null);
        control.right = model.supportId.right;
        this.addControl('supportId', control);
      }
      
      if(model.version != null) {
        const control = new TypedFormControl<number>(model.version.value, null);
        control.right = model.version.right;
        this.addControl('version', control);
      }
      
      if(model.dateiColl != null) {
        const control = new DateiUpdateTM.FormArray(model.dateiColl.value || [], null);
        control.right = model.dateiColl.right;
        this.addControl('dateiColl', control);
      }
      
      this.addControl('markAsDeleted', new TypedFormControl<boolean>(model.markAsDeleted, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get id() {
      return this.controls.id as any as TypedFormControl<number>;
    }
    
    get absender() {
      return this.controls.absender as any as TypedFormControl<string>;
    }
    
    get absendername() {
      return this.controls.absendername as any as TypedFormControl<string>;
    }
    
    get betreff() {
      return this.controls.betreff as any as TypedFormControl<string>;
    }
    
    get cc() {
      return this.controls.cc as any as TypedFormControl<string>;
    }
    
    get datumUTC() {
      return this.controls.datumUTC as any as TypedFormControl<string>;
    }
    
    get empfaenger() {
      return this.controls.empfaenger as any as TypedFormControl<string>;
    }
    
    get empfaengername() {
      return this.controls.empfaengername as any as TypedFormControl<string>;
    }
    
    get nachricht() {
      return this.controls.nachricht as any as TypedFormControl<string>;
    }
    
    get isPosteingang() {
      return this.controls.isPosteingang as any as TypedFormControl<boolean>;
    }
    
    get isSpam() {
      return this.controls.isSpam as any as TypedFormControl<boolean>;
    }
    
    get verzeichnis() {
      return this.controls.verzeichnis as any as TypedFormControl<MailVerzeichnis>;
    }
    
    get supportId() {
      return this.controls.supportId as any as TypedFormControl<number>;
    }
    
    get version() {
      return this.controls.version as any as TypedFormControl<number>;
    }
    
    get dateiColl() {
      return this.controls.dateiColl as any as DateiUpdateTM.FormArray;
    }
    
    get markAsDeleted() {
      return this.controls.markAsDeleted as any as TypedFormControl<boolean>;
    }
    
    dateiCollAdd(value?: DateiUpdateTM, dontMarkAsDirty?: boolean): DateiUpdateTM.FormGroup  {
      if (this.disabled) return null;
      let control = new DateiUpdateTM.FormGroup(value ? value : DateiUpdateTM.empty());
      this.dateiColl.push(control);
      if (!dontMarkAsDirty) {
        this.dateiColl.markAsDirty();
      }
      return control;
    }
    
    dateiCollRemove(control: DateiUpdateTM.FormGroup, dontMarkAsDirty?: boolean): void {
      if (this.disabled) return;
      let array = this.dateiColl.typed.controls;
      let index = array.indexOf(control);
      let removed = this.dateiColl.typed.at(index);
      if (removed.model.id != 0) {
        removed.markAsDeleted.setValue(true);
      } else {
        this.dateiColl.removeAt(index);
      }
      if (!dontMarkAsDirty) {
        this.dateiColl.markAsDirty();
      }
    }
    
    dateiCollMarkAsDeleted(control: DateiUpdateTM.FormGroup, dontMarkAsDirty?: boolean): void {
      control.markAsDeleted.setValue(true);
      if (!dontMarkAsDirty) {
        this.dateiColl.markAsDirty();
      }
    }
    
    get model(): EMailCreateTM {
      return {
        id: this.typed.controls.id?.model,
        absender: this.typed.controls.absender != undefined ? {
          value: this.typed.controls.absender.model,
          right: this.typed.controls.absender.right,
        } : undefined,
        absendername: this.typed.controls.absendername != undefined ? {
          value: this.typed.controls.absendername.model,
          right: this.typed.controls.absendername.right,
        } : undefined,
        betreff: this.typed.controls.betreff != undefined ? {
          value: this.typed.controls.betreff.model,
          right: this.typed.controls.betreff.right,
        } : undefined,
        cc: this.typed.controls.cc != undefined ? {
          value: this.typed.controls.cc.model,
          right: this.typed.controls.cc.right,
        } : undefined,
        datumUTC: this.typed.controls.datumUTC != undefined ? {
          value: this.typed.controls.datumUTC.model,
          right: this.typed.controls.datumUTC.right,
        } : undefined,
        empfaenger: this.typed.controls.empfaenger != undefined ? {
          value: this.typed.controls.empfaenger.model,
          right: this.typed.controls.empfaenger.right,
        } : undefined,
        empfaengername: this.typed.controls.empfaengername != undefined ? {
          value: this.typed.controls.empfaengername.model,
          right: this.typed.controls.empfaengername.right,
        } : undefined,
        nachricht: this.typed.controls.nachricht != undefined ? {
          value: this.typed.controls.nachricht.model,
          right: this.typed.controls.nachricht.right,
        } : undefined,
        isPosteingang: this.typed.controls.isPosteingang != undefined ? {
          value: this.typed.controls.isPosteingang.model,
          right: this.typed.controls.isPosteingang.right,
        } : undefined,
        isSpam: this.typed.controls.isSpam != undefined ? {
          value: this.typed.controls.isSpam.model,
          right: this.typed.controls.isSpam.right,
        } : undefined,
        verzeichnis: this.typed.controls.verzeichnis != undefined ? {
          value: this.typed.controls.verzeichnis.model,
          right: this.typed.controls.verzeichnis.right,
        } : undefined,
        supportId: this.typed.controls.supportId != undefined ? {
          value: this.typed.controls.supportId.model,
          right: this.typed.controls.supportId.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        dateiColl: this.typed.controls.dateiColl != undefined ? {
          value: this.typed.controls.dateiColl.model,
          right: this.typed.controls.dateiColl.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
    
    getRawValue(): EMailCreateTM {
      return {
        id: this.typed.controls.id?.model,
        absender: this.typed.controls.absender != undefined ? {
          value: this.typed.controls.absender.model,
          right: this.typed.controls.absender.right,
        } : undefined,
        absendername: this.typed.controls.absendername != undefined ? {
          value: this.typed.controls.absendername.model,
          right: this.typed.controls.absendername.right,
        } : undefined,
        betreff: this.typed.controls.betreff != undefined ? {
          value: this.typed.controls.betreff.model,
          right: this.typed.controls.betreff.right,
        } : undefined,
        cc: this.typed.controls.cc != undefined ? {
          value: this.typed.controls.cc.model,
          right: this.typed.controls.cc.right,
        } : undefined,
        datumUTC: this.typed.controls.datumUTC != undefined ? {
          value: this.typed.controls.datumUTC.model,
          right: this.typed.controls.datumUTC.right,
        } : undefined,
        empfaenger: this.typed.controls.empfaenger != undefined ? {
          value: this.typed.controls.empfaenger.model,
          right: this.typed.controls.empfaenger.right,
        } : undefined,
        empfaengername: this.typed.controls.empfaengername != undefined ? {
          value: this.typed.controls.empfaengername.model,
          right: this.typed.controls.empfaengername.right,
        } : undefined,
        nachricht: this.typed.controls.nachricht != undefined ? {
          value: this.typed.controls.nachricht.model,
          right: this.typed.controls.nachricht.right,
        } : undefined,
        isPosteingang: this.typed.controls.isPosteingang != undefined ? {
          value: this.typed.controls.isPosteingang.model,
          right: this.typed.controls.isPosteingang.right,
        } : undefined,
        isSpam: this.typed.controls.isSpam != undefined ? {
          value: this.typed.controls.isSpam.model,
          right: this.typed.controls.isSpam.right,
        } : undefined,
        verzeichnis: this.typed.controls.verzeichnis != undefined ? {
          value: this.typed.controls.verzeichnis.model,
          right: this.typed.controls.verzeichnis.right,
        } : undefined,
        supportId: this.typed.controls.supportId != undefined ? {
          value: this.typed.controls.supportId.model,
          right: this.typed.controls.supportId.right,
        } : undefined,
        version: this.typed.controls.version != undefined ? {
          value: this.typed.controls.version.model,
          right: this.typed.controls.version.right,
        } : undefined,
        dateiColl: this.typed.controls.dateiColl != undefined ? {
          value: this.typed.controls.dateiColl.getRawValue(),
          right: this.typed.controls.dateiColl.right,
        } : undefined,
        markAsDeleted: this.typed.controls.markAsDeleted?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<EMailCreateTM.Value, EMailCreateTM.FormGroup> {
    
    constructor(model?: EMailCreateTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new EMailCreateTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as EMailCreateTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<EMailCreateTM.Value, EMailCreateTM.FormGroup> {
    
    constructor(model?: { [key: string]: EMailCreateTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new EMailCreateTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface VerfahrenRequestTM {
  kunden?: number[] | null;
  verfahrenFilter?: VerfahrenListRequestTM | null;
  page: number;
  pageSize: number;
  sort?: string | null;
  sortOrder: SortOrder;
}

export namespace VerfahrenRequestTM {

  export function empty(): VerfahrenRequestTM {
    return {
      kunden: [],
      verfahrenFilter: VerfahrenListRequestTM.empty(),
      page: 0,
      pageSize: 0,
      sort: null,
      sortOrder: -1,
    };
  }
  
  export interface Value {
    kunden: number[] | null;
    verfahrenFilter: VerfahrenListRequestTM.Value | null;
    page: number;
    pageSize: number;
    sort: string | null;
    sortOrder: SortOrder;
  }
  
  interface Controls {
    kunden: TypedFormControl<number[]>;
    verfahrenFilter: VerfahrenListRequestTM.FormGroup;
    page: TypedFormControl<number>;
    pageSize: TypedFormControl<number>;
    sort: TypedFormControl<string>;
    sortOrder: TypedFormControl<SortOrder>;
  }
  
  export class FormGroup extends TypedFormGroup<VerfahrenRequestTM.Value, Controls> {
    
    private _model: VerfahrenRequestTM;
    
    public get originalModel(): VerfahrenRequestTM {
      return this._model;
    }
    
    constructor(model: VerfahrenRequestTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('kunden', new TypedFormControl<number[]>(model.kunden || [], null));
      
      if(model.verfahrenFilter != null) { this.addControl('verfahrenFilter', new VerfahrenListRequestTM.FormGroup(model.verfahrenFilter, null)); }
      
      this.addControl('page', new TypedFormControl<number>(model.page, Validators.compose([OnValidators.required])));
      
      this.addControl('pageSize', new TypedFormControl<number>(model.pageSize, Validators.compose([OnValidators.required])));
      
      this.addControl('sort', new TypedFormControl<string>(model.sort, null));
      
      this.addControl('sortOrder', new TypedFormControl<SortOrder>(model.sortOrder, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get kunden() {
      return this.controls.kunden as any as TypedFormControl<number[]>;
    }
    
    get verfahrenFilter() {
      return this.controls.verfahrenFilter as any as VerfahrenListRequestTM.FormGroup;
    }
    
    get page() {
      return this.controls.page as any as TypedFormControl<number>;
    }
    
    get pageSize() {
      return this.controls.pageSize as any as TypedFormControl<number>;
    }
    
    get sort() {
      return this.controls.sort as any as TypedFormControl<string>;
    }
    
    get sortOrder() {
      return this.controls.sortOrder as any as TypedFormControl<SortOrder>;
    }
    
    get model(): VerfahrenRequestTM {
      return {
        kunden: this.typed.controls.kunden?.model,
        verfahrenFilter: this.typed.controls.verfahrenFilter?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
    
    getRawValue(): VerfahrenRequestTM {
      return {
        kunden: this.typed.controls.kunden?.model,
        verfahrenFilter: this.typed.controls.verfahrenFilter?.getRawValue(),
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<VerfahrenRequestTM.Value, VerfahrenRequestTM.FormGroup> {
    
    constructor(model?: VerfahrenRequestTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new VerfahrenRequestTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as VerfahrenRequestTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<VerfahrenRequestTM.Value, VerfahrenRequestTM.FormGroup> {
    
    constructor(model?: { [key: string]: VerfahrenRequestTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new VerfahrenRequestTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface VerfahrenListRequestTM {
  isAbgeschlossen: boolean;
  isGeloescht: boolean;
  hasEinzellizenz: boolean;
  page: number;
  pageSize: number;
  sort?: string | null;
  sortOrder: SortOrder;
}

export namespace VerfahrenListRequestTM {

  export function empty(): VerfahrenListRequestTM {
    return {
      isAbgeschlossen: false,
      isGeloescht: false,
      hasEinzellizenz: false,
      page: 0,
      pageSize: 0,
      sort: null,
      sortOrder: -1,
    };
  }
  
  export interface Value {
    isAbgeschlossen: boolean;
    isGeloescht: boolean;
    hasEinzellizenz: boolean;
    page: number;
    pageSize: number;
    sort: string | null;
    sortOrder: SortOrder;
  }
  
  interface Controls {
    isAbgeschlossen: TypedFormControl<boolean>;
    isGeloescht: TypedFormControl<boolean>;
    hasEinzellizenz: TypedFormControl<boolean>;
    page: TypedFormControl<number>;
    pageSize: TypedFormControl<number>;
    sort: TypedFormControl<string>;
    sortOrder: TypedFormControl<SortOrder>;
  }
  
  export class FormGroup extends TypedFormGroup<VerfahrenListRequestTM.Value, Controls> {
    
    private _model: VerfahrenListRequestTM;
    
    public get originalModel(): VerfahrenListRequestTM {
      return this._model;
    }
    
    constructor(model: VerfahrenListRequestTM, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super({}, validator, asyncValidator);
      if (!model) {
        model = empty();
      }
      this._model = model;
      
      this.addControl('isAbgeschlossen', new TypedFormControl<boolean>(model.isAbgeschlossen, null));
      
      this.addControl('isGeloescht', new TypedFormControl<boolean>(model.isGeloescht, null));
      
      this.addControl('hasEinzellizenz', new TypedFormControl<boolean>(model.hasEinzellizenz, null));
      
      this.addControl('page', new TypedFormControl<number>(model.page, Validators.compose([OnValidators.required])));
      
      this.addControl('pageSize', new TypedFormControl<number>(model.pageSize, Validators.compose([OnValidators.required])));
      
      this.addControl('sort', new TypedFormControl<string>(model.sort, null));
      
      this.addControl('sortOrder', new TypedFormControl<SortOrder>(model.sortOrder, null));
      
      if (this.validatorGetter?.getCustomValidators) {
        this.validatorGetter.getCustomValidators(this);
      }
      
      if (this.validatorGetter?.getCustomAsyncValidators) {
        this.validatorGetter.getCustomAsyncValidators(this);
      }
    }
    
    get isAbgeschlossen() {
      return this.controls.isAbgeschlossen as any as TypedFormControl<boolean>;
    }
    
    get isGeloescht() {
      return this.controls.isGeloescht as any as TypedFormControl<boolean>;
    }
    
    get hasEinzellizenz() {
      return this.controls.hasEinzellizenz as any as TypedFormControl<boolean>;
    }
    
    get page() {
      return this.controls.page as any as TypedFormControl<number>;
    }
    
    get pageSize() {
      return this.controls.pageSize as any as TypedFormControl<number>;
    }
    
    get sort() {
      return this.controls.sort as any as TypedFormControl<string>;
    }
    
    get sortOrder() {
      return this.controls.sortOrder as any as TypedFormControl<SortOrder>;
    }
    
    get model(): VerfahrenListRequestTM {
      return {
        isAbgeschlossen: this.typed.controls.isAbgeschlossen?.model,
        isGeloescht: this.typed.controls.isGeloescht?.model,
        hasEinzellizenz: this.typed.controls.hasEinzellizenz?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
    
    getRawValue(): VerfahrenListRequestTM {
      return {
        isAbgeschlossen: this.typed.controls.isAbgeschlossen?.model,
        isGeloescht: this.typed.controls.isGeloescht?.model,
        hasEinzellizenz: this.typed.controls.hasEinzellizenz?.model,
        page: this.typed.controls.page?.model,
        pageSize: this.typed.controls.pageSize?.model,
        sort: this.typed.controls.sort?.model,
        sortOrder: this.typed.controls.sortOrder?.model,
      };
    }
  }
  
  export class FormArray extends TypedFormArray<VerfahrenListRequestTM.Value, VerfahrenListRequestTM.FormGroup> {
    
    constructor(model?: VerfahrenListRequestTM[], validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super((model || []).map(m => new VerfahrenListRequestTM.FormGroup(m, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return this.typed.controls.map(c => c.model);
    }
    
    getRawValue() {
      return this.typed.controls.map(c => c.getRawValue());
    }
    get controlsWithoutDeleted() {
      return this.typed.controls.filter(c => !(c as any).markAsDeleted.value) as VerfahrenListRequestTM.FormGroup[];
    }
  }
  
  export class FormDictionary extends TypedFormDictionary<VerfahrenListRequestTM.Value, VerfahrenListRequestTM.FormGroup> {
    
    constructor(model?: { [key: string]: VerfahrenListRequestTM }, validator?: ValidatorFn | ValidatorFn[] | null, asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null, private validatorGetter?: ValidatorGetter) {
      super(mapValues(model || {}, value => new VerfahrenListRequestTM.FormGroup(value, null, null, validatorGetter)), validator, asyncValidator);
    }
    
    get model() {
      return mapValues(this.typed.controls, c =>  c.model);
    }
    
    getRawValue() {
      return mapValues(this.typed.controls, c =>  c.getRawValue());
    }
  }
}

export interface VerfahrenListItemTM {
  verfahrenId: number;
  bezeichnung?: string | null;
  anzahlLieferanten: number;
  speicherplatz: number;
  laufzeit: number;
  einzellizenz: LizenzStatus;
  verfahrenAbgeschlossen: boolean;
  verfahrenGeloescht: boolean;
  abgeschlossenAmUTC?: string | null;
  createdAtUTC?: string | null;
  deletedAtUTC?: string | null;
  kunde?: string | null;
  bestellungen?: BestellungenProMonatTM[] | null;
}

export interface BestellungenProMonatTM {
  anzahl: number;
  monat: number;
  jahr: number;
}